/*
 * @Author: hejk
 * @Email: hejk@hxmec.com
 * @Date: 2019-12-09 09:37:57
 * @Last Modified by: xujh
 * @Last Modified time: 2020-07-09 15:02:14
 * @Description: 项目管理-项目列表
 */
import View from '@/components/view';

const ProjectManage = () => import(/* webpackChunkName: "yq-project-manage-index" */ '@/pages/aup/manage-org/proj-out/project-manage/index'); // 项目管理
// const Index = () => import( '@/pages/aup/enterprise-org/proj-out/project-manage/index');
const routes = [
    {
        path: 'project-manage',
        component: View,
        meta: {
            title: '项目管理'
        },
        children: [
            {
                path: 'index',
                component: ProjectManage,
                meta: {
                    title: '项目列表'
                }
            }
        ]
    }
];

export default routes;
