/*
 * @Author: hejk
 * @Email: hejk@hxmec.com
 * @Date: 2019-11-26 11:11:01
 * @Last Modified by: xujh
 * @Last Modified time: 2020-06-19 14:56:33
 * @Description: 产品信息
 */
<template>
    <div>
        <bk-form v-if="showProd"
                 ref="form"
                 :form-config="formConfig" />
    </div>
</template>
<script>
import common from '@/api/aup/common';

export default {
    data() {
        const noRequired = {
            rules: [
                { required: false }
            ]
        };
        const proItemBefore = [
            {
                fieldName: '产品名称',
                grid: 2,
                ...noRequired
            },
            {
                fieldName: '分类名称',
                grid: 2,
                ...noRequired
            },
            {
                fieldName: '目录名称',
                name: 'prodCatalogName',
                grid: 2,
                ...noRequired
            },
            {
                fieldName: '最小计量单位',
                grid: 2,
                ...noRequired
            },
            // {
            //     fieldName: '国家统一编码',
            //     grid: 2,
            //     ...noRequired
            // },
            {
                fieldName: '换算比',
                grid: 2,
                ...noRequired
            },
            {
                fieldName: '最小包装单位',
                grid: 2,
                ...noRequired
            },
            {
                fieldName: '最小包装规格',
                grid: 2,
                ...noRequired
            },
            {
                fieldName: '包装材质',
                grid: 2,
                ...noRequired
            },
            {
                fieldName: '产品材质',
                grid: 2,
                ...noRequired
            },
            {
                fieldName: '品牌/商标',
                grid: 2,
                ...noRequired
            },
            {
                fieldName: '产地',
                grid: 2,
                ...noRequired
            },
            {
                fieldName: '产品标准',
                grid: 2,
                ...noRequired
            },
            {
                fieldName: '产品有效期',
                grid: 2,
                ...noRequired
            }
        ];
        const proItemAfter = [
            {
                fieldName: '性能结构与组成',
                ...noRequired
            },
            {
                fieldName: '适用范围',
                ...noRequired
            },
            {
                fieldName: '规格',
                label: '产品规格',
                ...noRequired
            },
            {
                fieldName: '型号',
                label: '产品型号',
                ...noRequired
            }, '产品使用方法', '备注1'
        ];
        const proFormBefore = common.getForm(proItemBefore);
        const proFormAfter = common.getForm(proItemAfter);
        return {
            showProd: false,
            proFormBefore,
            proFormAfter,
            formConfig: {
                readonly: true,
                data: [
                    {
                        title: {
                            label: '产品信息'
                        },
                        data: []
                    }
                ]
            }
        };
    },
    mounted() {
        const { purPlatformProdCode, projectId } = this.$route.query;
        this.$fetch({
            url: '/aup/api/aup/prod/enroll/info/getByPurPlatformProdCode',
            method: 'post',
            params: {
                purPlatformProdCode,
                projectId
            }
        }).then(res => {
            if (res.code !== 0) {
                return;
            }
            this.$emit('setProdId', res.data.id);
            this.showProd = false;
            const { extFields } = res.data;
            const dyList = this.setDynamic(extFields);
            this.formConfig.data[0].data = [
                ...this.proFormBefore,
                ...dyList,
                ...this.proFormAfter
            ];
            setTimeout(() => {
                this.showProd = true;
                this.$nextTick(() => {
                    this.$refs.form.formConfig.results = res.data;
                });
            }, 100);
        });
    },
    methods: {
        /**
         * 拼接动态表单
         * @param {Object} list 动态数据
         */
        setDynamic(list) {
            const dynamic = [];
            if (list && list.length !== 0) {
                list.forEach(item => {
                    const dynamicItem = {
                        type: 'input',
                        grid: 2,
                        label: item.catalogFieldName,
                        name: `cata${item.id}`,
                        value: item.inputDefaultValue
                    };
                    dynamic.push(dynamicItem);
                });
            }
            return dynamic;
        }
    }
};
</script>
