/*
 * @Author: hejk
 * @Email: hejk@hxmec.com
 * @Date: 2020-09-22 14:55:46
 * @Last Modified by: hejk
 * @Last Modified time: 2020-09-22 14:58:52
 * @Description: 阳光采购数据关联
 */
import View from '@/components/view';

const Index = () => import(/* webpackChunkName: "yq-data-association-index" */ '@/pages/aup/enterprise-org/proj-out/data-association/index'); // 企业申报公示
const routes = [
    {
        path: 'data-association',
        component: View,
        meta: {
            title: '阳光采购数据处理'
        },
        children: [
            {
                path: 'index',
                component: Index,
                meta: {
                    title: '阳光采购数据关联'
                }
            }
        ]
    }
];

export default routes;
