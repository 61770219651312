/*
 * @Author: hejk
 * @Email: hejk@hxmec.com
 * @Date: 2019-11-12 11:19:11
 * @Last Modified by: hejk
 * @Last Modified time: 2020-11-27 09:50:49
 * @Description: 医院端-项目内
 * @Route: Route
 */
import View from '@/components/view';
import fetch from '@/utils/fetch';
import distributionManage from './distribution-manage';

const PurchaseQuantityFill = () => import(/* webpackChunkName: "yy-purchase-quantity-fill" */ '@/pages/aup/hosp-org/proj-inner/volume-manage/purchase-quantity-fill');
const HistoryVolumeFill = () => import(/* webpackChunkName: "yy-history-volume-fill" */ '@/pages/aup/hosp-org/proj-inner/history-volume-manage/history-volume-fill');
const PrePurchaseQuantityFill = () => import(/* webpackChunkName: "yy-pre-purchase-quantity-fill" */ '@/pages/aup/hosp-org/proj-inner/volume-manage/pre-purchase-quantity-fill');

const routes = [
    {
        path: 'proj-inner',
        component: View,
        meta: {
            title: '项目内'
        },
        children: [
            {
                path: 'volume-manage',
                component: View,
                meta: {
                    title: '报量管理'
                },
                children: [
                    {
                        path: 'volume-manage',
                        component: PurchaseQuantityFill,
                        meta: {
                            title: '采购量填报',
                            display: 'none'
                        }
                    },
                    {
                        path: 'volume-manage-before',
                        component: () => import('@/pages/common/notice'),
                        meta: {
                            title: '采购量填报'
                        },
                        beforeEnter: (to, from, next) => {
                            fetch({
                                url: '/aup/api/aup/quantity/filling/status',
                                method: 'get',
                                params: {
                                    projectId: to.query.itemId || 1
                                }
                            }).then(res => {
                                const { status } = res.data;
                                to.query.startTime = res.data.startTime;
                                to.query.endTime = res.data.endTime;
                                if (status === 0) {
                                    to.query.title = '采购量填报未开始！';
                                    next();
                                } else if (status === 3) {
                                    to.query.title = '采购量填报已结束！';
                                    next();
                                } else if (status === 2) {
                                    to.query.title = '采购量填报已暂停！';
                                    next();
                                } else {
                                    next({
                                        path: '/aup/hosp-org/proj-inner/volume-manage/volume-manage',
                                        query: from.query
                                    });
                                }
                            });
                        }
                    },
                    {
                        path: 'pre-purchase-quantity-fill',
                        component: PrePurchaseQuantityFill,
                        meta: {
                            title: '预采购量填报'
                        }
                    }
                ]
            },
            {
                path: 'history-volume-manage',
                component: View,
                meta: {
                    title: '历史采购量填报'
                },
                children: [
                    {
                        path: 'history-volume-fill',
                        component: HistoryVolumeFill,
                        meta: {
                            title: '采购量填报'
                        }
                    },
                    {
                        path: 'volume-manage-before',
                        component: () => import('@/pages/common/notice'),
                        meta: {
                            title: '采购量填报',
                            display: 'none'
                        },
                        beforeEnter: (to, from, next) => {
                            fetch({
                                url: '/aup/api/aup/quantity/filling/status',
                                method: 'get',
                                params: {
                                    projectId: to.query.itemId || 1,
                                    dataType: 1
                                }
                            }).then(res => {
                                const { status } = res.data;
                                to.query.startTime = res.data.startTime;
                                to.query.endTime = res.data.endTime;
                                if (status === 0) {
                                    to.query.title = '采购量填报未开始！';
                                    next();
                                } else if (status === 3) {
                                    to.query.title = '采购量填报已结束！';
                                    next();
                                } else if (status === 2) {
                                    to.query.title = '采购量填报已暂停！';
                                    next();
                                } else {
                                    next({
                                        path: '/aup/hosp-org/proj-inner/history-volume-manage/history-volume-fill',
                                        query: from.query
                                    });
                                }
                            });
                        }
                    }
                ]
            },
            ...distributionManage
        ]
    }
];

export default routes;
