/*
 * @Author: hejk
 * @Email: hejk@hxmec.com
 * @Date: 2021-07-07 14:03:47
 * @Last Modified by: hejk
 * @Last Modified time: 2021-07-07 14:04:51
 * @Description: 数据导出
 */
const dataExport = () => import(/* webpackChunkName: "data-export" */ '@/pages/aup/manage-org/proj-inner/data-export'); // 数据导出

export default [
    {
        path: 'data-export',
        component: dataExport,
        meta: {
            title: '数据导出'
        }
    }
];
