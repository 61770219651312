/*
 * @Author: linyq
 * @Email: guanghsy2000@163.com
 * @Date: 2019-11-12 19:10:26
 * @Last Modified by: hejk
 * @Last Modified time: 2021-09-16 20:09:14
 * @Description: 报价管理
 */
// import fetch from '@/utils/fetch';

const ProductDetail = () => import(/* webpackChunkName: "yq-prod-detail" */ '@/pages/aup/enterprise-org/proj-out/declare-manage/product/detail'); // 产品资料详情


export default [
    {
        path: 'encrypt',
        component: () => import(/* webpackChunkName: "qy-bid-management-encrypt" */ '@/pages/aup/enterprise-org/proj-inner/bid-management/encrypt'),
        meta: {
            title: '加密报价',
            display: 'none'
        }
    },
    {
        path: 'encrypt-before',
        meta: {
            title: '加密报价'
        },
        component: () => import(/* webpackChunkName: "qy-bid-management-encrypt" */ '@/pages/aup/enterprise-org/proj-inner/bid-management/encrypt')
        // component: () => import('@/components/notice'),
        // beforeEnter: (to, from, next) => {
        //     fetch({
        //         url: '/aup/api/aup/enroll/round/quotation/page/org/quotationStatus',
        //         method: 'get',
        //         params: {
        //             projectId: to.query.itemId || 1,
        //             pageSize: 1,
        //             pageNo: 1
        //         }
        //     }).then(res => {
        //         const { status } = res.data;
        //         to.query.startTime = res.data.startTime;
        //         to.query.endTime = res.data.endTime;
        //         if (status === 0) {
        //             to.query.title = '报价环节未启动！';
        //             next();
        //         } else if (status === 2) {
        //             to.query.title = '报价环节已暂停！';
        //             next();
        //         } else if (status === 3) {
        //             to.query.title = '报价环节已结束！';
        //             next();
        //         } else {
        //             next({
        //                 path: '/aup/enterprise-org/proj-inner/bid-management/encrypt',
        //                 query: from.query
        //             });
        //         }
        //     });
        // }
    },
    {
        path: 'encryption-bid/product-detail',
        component: ProductDetail,
        meta: {
            title: '产品资料详情',
            noTag: true,
            display: 'none'
        }
    },
    {
        path: 'quotation-entry-enquiry',
        component: () => import(/* webpackChunkName: "qy-quotation-entry-enquiry" */ '@/pages/aup/enterprise-org/proj-inner/bid-management/quotation-entry-enquiry'),
        meta: {
            title: '报价入选公示'
        }
    },
    {
        path: 'catalog-bid',
        component: () => import(/* webpackChunkName: "qy-bid-management-catalog" */ '@/pages/aup/enterprise-org/proj-inner/bid-management/catalog-bid'),
        meta: {
            title: '目录报价'
        }
    },
    {
        path: 'catalog-bid/knead-list',
        component: () => import(/* webpackChunkName: "qy-bid-management-knead-list" */ '@/pages/aup/enterprise-org/proj-inner/bid-management/knead-list'),
        meta: {
            title: '捏合产品列表',
            display: 'none'
        }
    },
    {
        path: 'catalog-bid/knead-list/detail',
        component: () => import(/* webpackChunkName: "prod-info" */ '@/pages/aup/components/result-public/prod-info'),
        meta: {
            title: '详情',
            display: 'none'
        }
    },
    {
        path: 'cat-quotation-entry-enquiry',
        component: () => import(/* webpackChunkName: "qy-cat-quotation-entry-enquiry" */ '@/pages/aup/enterprise-org/proj-inner/bid-management/cat-quotation-entry-enquiry'),
        meta: {
            title: '报价入选公示'
        }
    }
];
