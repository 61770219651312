/*
 * @Author: qiuwt
 * @Email: qiuwt@hxmec.com
 * @Date: 2020-01-16 16:19:35
 * @Last Modified by: qiuwt
 * @Last Modified time: 2020-04-26 11:46:49
 * @Description: 微前端入口
 */


import Info from './base';
import routes from '../router/aup';

const VueLifecycles = Info({
    publicPath: 'aup',
    routes
});

export const bootstrap = [
    VueLifecycles.bootstrap
];
// 项目启动后的钩子
export const mount = [
    VueLifecycles.mount
];
// 项目卸载的钩子
export const unmount = [
    VueLifecycles.unmount
];
