/*
 * @Author: hejk
 * @Email: hejk@hxmec.com
 * @Date: 2020-04-09 13:45:32
 * @Last Modified by: xujh
 * @Last Modified time: 2020-07-09 15:02:57
 * @Description: 申投诉管理
 */
import View from '@/components/view';

const appeal = () => import(/* webpackChunkName: "yq-appeal" */ '@/pages/aup/enterprise-org/proj-out/appeal-manage/appeal'); // 发起申投诉
const detail = () => import(/* webpackChunkName: "yq-appeal-detail" */ '@/pages/aup/enterprise-org/proj-out/appeal-manage/detail'); // 详情
const modify = () => import(/* webpackChunkName: "yq-appeal-modify" */ '@/pages/aup/enterprise-org/proj-out/appeal-manage/modify'); // 编辑
const routes = [
    {
        path: 'appeal-manage',
        component: View,
        meta: {
            title: '申投诉管理'
        },
        children: [
            {
                path: 'appeal',
                component: appeal,
                meta: {
                    title: '发起申投诉'
                }
            },
            {
                path: 'appeal/detail',
                component: detail,
                meta: {
                    title: '详情',
                    noTag: true,
                    display: 'none'
                }
            },
            {
                path: 'appeal/add',
                component: modify,
                meta: {
                    title: '新增',
                    noTag: true,
                    display: 'none'
                }
            },
            {
                path: 'appeal/modify',
                component: modify,
                meta: {
                    title: '修改',
                    noTag: true,
                    display: 'none'
                }
            }
        ]
    }
];

export default routes;
