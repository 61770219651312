/*
 * @Author: hejk
 * @Email: hejk@hxmec.com
 * @Date: 2019-11-12 11:19:11
 * @Last Modified by: hejk
 * @Last Modified time: 2021-07-07 14:05:50
 * @Description: 企业端-项目内
 * @Route: Route
 */
import View from '@/components/view';
import VolumeManage from './volume-manage';
import QuotationManage from './quotation-manage';
import ProjectConfig from './project-config';
import selectResults from './select-results';
import biddingManage from './bidding-manage';
import directManage from './direction-manage';
import negotManage from './negotiate-manage';
import prodRegister from './prod-register';
import statistics from './statistics';
import distributionResult from './distribution-manage';
import directionManage from './directory-manage';
import historyPurchaseManage from './history-purchase-manage';
import priceLimitPub from './price-limit-pub';
import directNetManage from './direct-net-manage';
import dataExport from './data-export';

const Index = () => import('@/pages/aup/enterprise-org/proj-out/index');
const routes = [
    {
        path: 'proj-inner',
        component: View,
        meta: {
            title: '项目内'
        },
        children: [
            {
                path: 'index',
                component: Index,
                meta: {
                    title: '工作台'
                }
            },
            ...VolumeManage,
            ...QuotationManage,
            ...ProjectConfig,
            ...selectResults,
            ...biddingManage,
            ...directManage,
            ...negotManage,
            ...prodRegister,
            ...statistics,
            ...distributionResult,
            ...directionManage,
            ...historyPurchaseManage,
            ...priceLimitPub,
            ...directNetManage,
            ...dataExport
        ]
    }
];

export default routes;
