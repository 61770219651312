/*
 * @Author: hejk
 * @Email: hejk@hxmec.com
 * @Date: 2019-12-09 11:28:24
 * @Last Modified by: hejk
 * @Last Modified time: 2020-05-19 15:08:29
 * @Description: 系统管理
 */
import View from '@/components/view';

const ParamConfig = () => import(/* webpackChunkName: "gl-param-config" */ '@/pages/aup/manage-org/proj-out/system-manage/param-config');
const protocolTemplate = () => import(/* webpackChunkName: "gl-protocol-template" */ '@/pages/aup/manage-org/proj-out/system-manage/protocol-template');
const protocolTemplateDetail = () => import(/* webpackChunkName: "gl-protocol-template-detail" */ '@/pages/aup/manage-org/proj-out/system-manage/protocol-template-detail');
const routes = [
    {
        path: 'system-manage',
        component: View,
        meta: {
            title: '系统管理'
        },
        children: [
            {
                path: 'help-world',
                component: () => import(/* webpackChunkName: "gl-help-world" */ '@/pages/aup/manage-org/proj-out/system-manage/help-world'),
                meta: {
                    title: '帮助文档管理'
                }
            },
            {
                path: 'notice',
                component: () => import(/* webpackChunkName: "gl-notice" */ '@/pages/aup/manage-org/proj-out/system-manage/notice'),
                meta: {
                    title: '通知公告'
                }
            },
            {
                path: 'param-config',
                component: ParamConfig,
                meta: {
                    title: '参数配置'
                }
            },
            {
                path: 'protocol-template',
                component: protocolTemplate,
                meta: {
                    title: '协议模板管理'
                }
            },
            {
                path: 'protocol-template/add',
                component: protocolTemplateDetail,
                meta: {
                    title: '新增',
                    display: 'none'
                }
            },
            {
                path: 'protocol-template/edit',
                component: protocolTemplateDetail,
                meta: {
                    title: '编辑',
                    display: 'none'
                }
            },
            {
                path: 'protocol-template/detail',
                component: protocolTemplateDetail,
                meta: {
                    title: '详情',
                    display: 'none'
                }
            }
        ]
    }
];

export default routes;
