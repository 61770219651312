/*
 * @Author: linyq
 * @Email: guanghsy2000@163.com
 * @Date: 2020-01-19 14:52:23
 * @Last Modified by: hejk
 * @Last Modified time: 2020-05-11 14:54:45
 * @Description: 采购目录管理
 */

import View from '@/components/view';

const directManage = () => import(/* webpackChunkName: "gl-direction-manage" */ '@/pages/aup/manage-org/proj-inner/direction-manage/index');

const routes = [
    {
        path: 'direction',
        component: View,
        meta: {
            title: '目录管理'
        },
        children: [
            {
                path: 'manage',
                component: directManage,
                meta: {
                    title: '采购目录管理'
                }
            }
        ]
    }
];

export default routes;
