/*
 * @Author: hejk
 * @Email: hejk@hxmec.com
 * @Date: 2019-11-12 11:09:37
 * @Last Modified by: hejk
 * @Last Modified time: 2019-12-10 15:40:21
 * @Description: 企业端路由
 * @Route: Route
 */
import View from '@/components/view';
import Inner from './inner';
import Out from './out'; 

const routes = [
    {
        path: 'enterprise-org',
        component: View,
        meta: {
            title: '企业端'
        },
        children: [
            ...Inner,
            ...Out
        ]
    }
];

export default routes;
