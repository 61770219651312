/*
 * @Author: linyq
 * @Email: guanghsy2000@163.com
 * @Date: 2019-11-12 19:10:26
 * @Last Modified by: hejk
 * @Last Modified time: 2020-05-11 14:36:44
 * @Description: 目录管理
 */

export default [
    {
        path: 'purchas-directory',
        component: () => import(/* webpackChunkName: "qy-purchas-directory" */ '@/pages/aup/enterprise-org/proj-inner/directory-management/purchase-directory'),
        meta: {
            title: '采购目录'
        }
    }
];
