/*
 * @Author: hejk
 * @Email: hejk@hxmec.com
 * @Date: 2020-02-25 15:05:54
 * @Last Modified by: hejk
 * @Last Modified time: 2020-02-25 15:17:07
 * @Description: 分类目录
 */
export const newCatalogue = [
    '01有源手术器械',
    '02无源手术器械',
    '03神经和心血管手术器械',
    '04骨科手术器械',
    '05放射治疗器械',
    '06医用成像器械',
    '07医用诊察和监护器械',
    '08呼吸、麻醉和急救器械',
    '09物理治疗器械',
    '10输血、透析和体外循环器械',
    '11医疗器械消毒灭菌器械',
    '12有源植入器械',
    '13无源植入器械',
    '14注输、护理和防护器械',
    '15患者承载器械',
    '16眼科器械',
    '17口腔科器械',
    '18妇产科、辅助生殖和避孕器械',
    '19医用康复器械',
    '20中医器械',
    '21医用软件',
    '22临床检验器械'
];
export const oldCatalogue = [
    '6801基础外科手术器械',
    '6802显微外科手术器械',
    '6803神经外科手术器械',
    '6804眼科手术器械',
    '6805耳鼻喉科手术器械',
    '6806口腔科手术器械',
    '6807胸腔心血管外科手术器械',
    '6808腹部外科手术器械',
    '6809泌尿肛肠外科手术器械',
    '6810矫形外科（骨科）手术器械',
    '6812妇产科用手术器械',
    '6813计划生育手术器械',
    '6815注射穿刺器械',
    '6816烧伤(整形)科手术器械',
    '6820普通诊察器械',
    '6821医用电子仪器设备',
    '6822医用光学器具、仪器及内窥镜设备',
    '6823医用超声仪器及有关设备',
    '6824医用激光仪器设备',
    '6825医用高频仪器设备',
    '6826物理治疗及康复设备',
    '6827中医器械',
    '6828医用磁共振设备',
    '6830医用X射线设备',
    '6831医用X射线附属设备及部件',
    '6832医用高能射线设备',
    '6833医用核素设备',
    '6834医用射线防护用品、装置',
    '6840临床检验分析仪器',
    '6840体外诊断试剂',
    '6841医用化验和基础设备器具',
    '6845体外循环及血液处理设备',
    '6846植入材料和人工器官',
    '6854手术室、急救室、诊疗室设备及器具',
    '6855口腔科设备及器具',
    '6856病房护理设备及器具',
    '6857消毒和灭菌设备及器具',
    '6858医用冷疗、低温、冷藏设备及器具',
    '6863口腔科材料',
    '6864医用卫生材料及敷料',
    '6865医用缝合材料及粘合剂',
    '6866医用高分子材料及制品',
    '6870软件',
    '6877介入器材'
];
