/*
 * @Author: hejk
 * @Email: hejk@hxmec.com
 * @Date: 2019-12-09 09:37:57
 * @Last Modified by: hejk
 * @Last Modified time: 2020-05-27 17:06:28
 * @Description: 配送方案管理（生产企业）-项目列表
 */
import View from '@/components/view';

const DeliveryProjectSelect = () => import(/* webpackChunkName: "yq-delivery-project-select" */ '@/pages/aup/enterprise-org/proj-inner/delivery-manage/delivery-project-select');
const Logs = () => import(/* webpackChunkName: "yq-delivery-manage-logs" */ '@/pages/aup/enterprise-org/proj-inner/delivery-manage/logs');
const Maintain = () => import(/* webpackChunkName: "yq-delivery-manage-maintain" */ '@/pages/aup/enterprise-org/proj-inner/delivery-manage/maintain');

const Contract = () => import(/* webpackChunkName: "yq-delivery-manage-contract-sign" */ '@/pages/aup/enterprise-org/proj-inner/delivery-manage/contract-sign');
const detail = () => import(/* webpackChunkName: "yq-delivery-manage-distribution-detail" */ '@/pages/aup/components/distribution-detail');

const routes = [
    {
        path: 'delivery-manage',
        component: View,
        meta: {
            title: '配送管理（生产企业）'
        },
        children: [
            {
                path: 'delivery-project-select',
                component: DeliveryProjectSelect,
                meta: {
                    title: '配送方案点选'
                }
            },
            {
                path: 'delivery-project-select/log',
                component: Logs,
                meta: {
                    title: '操作日志',
                    noTag: true,
                    display: 'none'
                }
            }, 
            {
                path: 'delivery-project-select/maintain',
                component: Maintain,
                meta: {
                    title: '配送方案维护',
                    noTag: true,
                    display: 'none'
                }
            },
            {
                path: 'contract-sign',
                component: Contract,
                meta: {
                    title: '合同签订'
                }
            },
            {
                path: 'contract-sign/detail',
                component: detail,
                meta: {
                    title: '明细',
                    display: 'none'
                }
            }
        ]
    }
];

export default routes;
