/*
 * @Author: xujh
 * @Email: xujh@hxmec.com
 * @Date: 2020-07-07 10:32:40
 * @Last Modified by: xujh
 * @Last Modified time: 2020-07-15 19:22:41
 * @Description: 企业端配送关系调整
 * @Route: /enterprise-org/proj-out/dynamic-adjust/list
 */
import View from '@/components/view';

const DistributionAdjust = () => import(/* webpackChunkName: "DistributionAdjust" */ '@/pages/aup/enterprise-org/proj-out/product-distribution-relationship/adjust');
const DistributionAdjustLog = () => import(/* webpackChunkName: "DistributionAdjustLog" */ '@/pages/aup/enterprise-org/proj-out/product-distribution-relationship/adjust-log');
const DeliveryDistributionAdjustLog = () => import(/* webpackChunkName: "DeliveryDistributionAdjustLog" */ '@/pages/aup/manage-org/proj-out/distribution-relationship/adjust-log');

const DistributionAdjustDetail = () => import(/* webpackChunkName: "DistributionAdjustDetail" */ '@/pages/aup/manage-org/proj-out/distribution-relationship/detail');
const ProductionDistrubution = () => import(/* webpackChunkName: "ProductionDistrubution" */ '@/pages/aup/enterprise-org/proj-out/product-distribution-relationship');
const DeliveryDistrubution = () => import(/* webpackChunkName: "DeliveryDistrubution" */ '@/pages/aup/enterprise-org/proj-out/delivery-distribution-relationship');
const DistributionAdjustConfirm = () => import(/* webpackChunkName: "DistributionAdjustConfirm" */ '@/pages/aup/enterprise-org/proj-out/delivery-distribution-relationship/adjust');
export default [
    {
        path: 'production-distribution-relationship',
        component: View,
        meta: {
            title: '配送关系调整(生产企业)'
        },
        children: [
            {
                path: 'list',
                component: ProductionDistrubution,
                meta: {
                    title: '配送关系调整'
                }
            },
            {
                path: 'adjust',
                component: DistributionAdjust,
                meta: {
                    title: '申请调整',
                    display: 'none'
                }
            },
            {
                path: 'adjust-log',
                component: DistributionAdjustLog,
                meta: {
                    title: '调整日志',
                    display: 'none'
                }
            },
            {
                path: 'adjust-log/detail',
                component: DistributionAdjustDetail,
                meta: {
                    title: '查看日志详情',
                    display: 'none'

                }
            }
        ]
    },
    {
        path: 'delivery-distribution-relationship',
        component: View,
        meta: {
            title: '配送关系调整(配送企业)'
        },
        children: [
            {
                path: 'list',
                component: DeliveryDistrubution,
                meta: {
                    title: '配送关系调整'
                }
            },
            {
                path: 'adjust-log',
                component: DeliveryDistributionAdjustLog,
                meta: {
                    title: '调整日志',
                    display: 'none'
                }
            },
            {
                path: 'adjust-log/detail',
                component: DistributionAdjustDetail,
                meta: {
                    title: '查看日志详情',
                    display: 'none'

                }
            },
            {
                path: 'confirm',
                component: DistributionAdjustConfirm,
                meta: {
                    title: '确认',
                    display: 'none'

                }
            },
            {
                path: 'detail',
                component: DistributionAdjustDetail,
                meta: {
                    title: '查看',
                    display: 'none'
                }
            }
        ]
    }
];
