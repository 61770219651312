/*
 * @Author: linyq
 * @Email: guanghsy2000@163.com
 * @Date: 2020-01-19 14:52:23
 * @Last Modified by: hejk
 * @Last Modified time: 2021-06-07 19:04:07
 * @Description: 项目报名
 */

import View from '@/components/view';

const projRegQuery = () => import(/* webpackChunkName: "gl-proj-register-query" */ '@/pages/aup/manage-org/proj-inner/proj-register/query');
const allowEnroll = () => import(/* webpackChunkName: "gl-proj-register-allow-enroll" */ '@/pages/aup/manage-org/proj-inner/proj-register/query/allow-enroll');
const enrolled = () => import(/* webpackChunkName: "gl-proj-register-enrolled" */ '@/pages/aup/manage-org/proj-inner/proj-register/query/enrolled');
const unEnrolled = () => import(/* webpackChunkName: "gl-proj-register-un-enroll" */ '@/pages/aup/manage-org/proj-inner/proj-register/query/un-enroll');
const adjust = () => import(/* webpackChunkName: "gl-proj-register-adjust" */ '@/pages/aup/manage-org/proj-inner/proj-register/adjust');
const logger = () => import(/* webpackChunkName: "gl-proj-register-adjust-log" */ '@/pages/aup/manage-org/proj-inner/proj-register/adjust/log');
const audit = () => import(/* webpackChunkName: "gl-proj-register-audit" */ '@/pages/aup/manage-org/proj-inner/proj-register/audit');
const auditLog = () => import(/* webpackChunkName: "gl-proj-register-audit-log" */ '@/pages/aup/manage-org/proj-inner/proj-register/audit/log');
const dataDeclare = () => import(/* webpackChunkName: "gl-proj-register-data-declare" */ '@/pages/aup/manage-org/proj-inner/proj-register/data-declare');
const dataDeclareAudit = () => import(/* webpackChunkName: "gl-proj-register-data-declare-audit" */ '@/pages/aup/manage-org/proj-inner/proj-register/data-declare/audit');
const proHistoryPriceFill = () => import(/* webpackChunkName: "gl-pro-history-price-fill" */ '@/pages/aup/manage-org/proj-inner/proj-register/pro-history-price-fill');
const proHistoryPriceFillAudit = () => import(/* webpackChunkName: "gl-pro-history-price-fill-audit" */ '@/pages/aup/manage-org/proj-inner/proj-register/pro-history-price-fill/audit');
const proHistoryPriceFillDetail = () => import(/* webpackChunkName: "gl-pro-history-price-fill-detail" */ '@/pages/aup/manage-org/proj-inner/proj-register/pro-history-price-fill/detail');
const historyPriceLog = () => import(/* webpackChunkName: "gl-history-price-log" */ '@/pages/aup/manage-org/proj-inner/proj-register/history-price-log');

const routes = [
    {
        path: 'proj-reg',
        component: View,
        meta: {
            title: '项目报名'
        },
        children: [
            {
                path: 'query',
                component: projRegQuery,
                meta: {
                    title: '产品报名查询'
                }
            },
            {
                path: 'query/allow-enroll',
                component: allowEnroll,
                meta: {
                    title: '可报名产品数',
                    display: 'none'
                }
            },
            {
                path: 'query/enrolled',
                component: enrolled,
                meta: {
                    title: '已报名产品数',
                    display: 'none'
                }
            },
            {
                path: 'query/un-enroll',
                component: unEnrolled,
                meta: {
                    title: '未报名产品数',
                    display: 'none'
                }
            },
            {
                path: 'adjust',
                component: adjust,
                meta: {
                    title: '产品目录调整'
                }
            },
            {
                path: 'adjust/log',
                component: logger,
                meta: {
                    title: '调整日志',
                    display: 'none'
                }
            },
            {
                path: 'audit',
                component: audit,
                meta: {
                    title: '产品报名审核'
                }
            },
            {
                path: 'audit/log',
                component: auditLog,
                meta: {
                    title: '审计日志',
                    display: 'none'
                }
            },
            {
                path: 'data-declare',
                component: dataDeclare,
                meta: {
                    title: '企业资料增补管理'
                }
            },
            {
                path: 'data-declare/audit',
                component: dataDeclareAudit,
                meta: {
                    title: '审核',
                    display: 'none'
                }
            },
            {
                path: 'data-declare/detail',
                component: dataDeclareAudit,
                meta: {
                    title: '详情',
                    display: 'none'
                }
            },
            {
                path: 'pro-history-price-fill',
                component: proHistoryPriceFill,
                meta: {
                    title: '历史价格审核管理'
                }
            },
            {
                path: 'pro-history-price-fill/audit',
                component: proHistoryPriceFillAudit,
                meta: {
                    title: '审核',
                    display: 'none'
                }
            },
            {
                path: 'pro-history-price-fill/detail',
                component: proHistoryPriceFillDetail,
                meta: {
                    title: '详情',
                    display: 'none'
                }
            },
            {
                path: 'history-price-log',
                component: historyPriceLog,
                meta: {
                    title: '历史价格日志查询'
                }
            },
            {
                path: 'history-price-log/detail',
                component: proHistoryPriceFillDetail,
                meta: {
                    title: '详情',
                    display: 'none'
                }
            }
        ]
    }
];

export default routes;
