/*
 * @Author: linyq
 * @Email: guanghsy2000@163.com
 * @Date: 2019-11-12 19:10:26
 * @Last Modified by: hejk
 * @Last Modified time: 2020-10-23 13:49:16
 * @Description: 竞价管理
 */

const ProductDetail = () => import(/* webpackChunkName: "yq-prod-detail" */ '@/pages/aup/enterprise-org/proj-out/declare-manage/product/detail'); // 产品资料详情

export default [
    {
        path: 'competitive-price-bid',
        component: () => import(/* webpackChunkName: "qy-competitive-price-bid" */ '@/pages/aup/enterprise-org/proj-inner/competitive-price/competitive-price-bid'),
        meta: {
            title: '竞价报价',
            display: 'none'
        }
    },
    {
        path: 'competitive-before',
        component: () => import(/* webpackChunkName: "qy-competitive-price-bid" */ '@/pages/aup/enterprise-org/proj-inner/competitive-price/competitive-price-bid'),
        meta: {
            title: '竞价报价'
        }
        // beforeEnter(to, from, next) {
        //     fetch({
        //         url: '/aup/api/aup/project/param/round/config/get_round_status',
        //         method: 'post',
        //         params: {
        //             projectId: to.query.itemId || 1,
        //             configType: 2 // 1. 解密开关 2. 竞价开关 3. 竞价规则
        //         }
        //     }).then(res => {
        //         const { startTime, endTime, status, roundNum } = res.data;
        //         to.query.startTime = startTime;
        //         to.query.endTime = endTime;
        //         if (status === 0) {
        //             to.query.title = `第${roundNum}轮竞价环节还未开始，请耐心等待!`;
        //             next();
        //         } else if (status === 2) {
        //             to.query.title = `第${roundNum}轮竞价环节已暂停，请耐心等待!`;
        //             next();
        //         } else if (status === 3) {
        //             to.query.title = `第${roundNum}轮竞价环节已结束!`;
        //             next();
        //         } else {
        //             next({
        //                 path: '/aup/enterprise-org/proj-inner/competitive-price/competitive-price-bid',
        //                 query: from.query
        //             });
        //         }
        //     });
        // }
    },
    {
        path: 'competitive-price-bid/product-detail',
        component: ProductDetail,
        meta: {
            title: '产品资料详情',
            noTag: true,
            display: 'none'
        }
    },
    {
        path: 'competitive-price-search/product-detail',
        component: ProductDetail,
        meta: {
            title: '产品资料详情',
            noTag: true,
            display: 'none'
        }
    },
    {
        path: 'competitive-price-search',
        component: () => import(/* webpackChunkName: "qy-competitive-price-search" */ '@/pages/aup/enterprise-org/proj-inner/competitive-price/competitive-price-search'),
        meta: {
            title: '竞价结果公示'
        }
    }
];
