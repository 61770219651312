/*
 * @Author: hejk
 * @Email: hejk@hxmec.com
 * @Date: 2019-11-12 11:19:11
 * @Last Modified by: hejk
 * @Last Modified time: 2020-09-22 14:57:21
 * @Description: 企业端-项目外
 * @Route: Route
 */
import View from '@/components/view';
import ProjectManage from './project-manage';
import Notice from './notice-announcement';
import Protocol from './protocol-manage';

import ShareResults from './share-results';

import DeclareManage from './declare-manage';
import DeclareResult from './declare-result';
import AppealManage from './appeal-manage';
import DynamicAdjust from './dynamic-adjust';
import ProductDistributionRelationship from './distribution-relationship';
import DataAssociation from './data-association';

const Index = () => import('@/pages/aup/enterprise-org/proj-out/index');

const routes = [
    {
        path: 'proj-out',
        component: View,
        meta: {
            title: '项目外'
        },
        children: [
            {
                path: 'index',
                component: Index,
                meta: {
                    title: '工作台'
                }
            },
            ...ProjectManage,
            ...Notice,
            ...Protocol,
            ...ShareResults,
            ...DeclareManage,
            ...DeclareResult,
            ...AppealManage,
            ...DynamicAdjust,
            ...ProductDistributionRelationship,
            ...DataAssociation
        ]
    }
];

export default routes;
