/*
 * @Author: hejk
 * @Email: hejk@hxmec.com
 * @Date: 2020-03-31 15:00:40
 * @Last Modified by: hejk
 * @Last Modified time: 2020-05-11 14:33:27
 * @Description: 目录管理
 */
import View from '@/components/view';

const BidCatalog = () => import(/* webpackChunkName: "qy-bid-catalog" */ '@/pages/aup/enterprise-org/proj-inner/directory-manage/bid-catalog'); // 招标目录管理

export default [
    {
        path: 'directory-manage',
        component: View,
        meta: {
            title: '目录管理'
        },
        children: [
            {
                path: 'bid-catalog',
                component: BidCatalog,
                meta: {
                    title: '招标目录'
                }
            }
        ]
    }
];
