/*
 * @Author: linyq
 * @Email: guanghsy2000@163.com
 * @Date: 2019-11-12 19:10:26
 * @Last Modified by: hejk
 * @Last Modified time: 2020-05-11 14:40:04
 * @Description: 入选结果路由
 */

// const basePath = '@/pages/aup/enterprise-org/proj-inner/';
export default [
    {
        path: 'select-result-query',
        component: () => import(/* webpackChunkName: "qy-select-result-query" */ '@/pages/aup/enterprise-org/proj-inner/select-result/select-result-query'),
        meta: {
            title: '入选结果公示'
        }
    }
];
