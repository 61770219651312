/*
 * @Author: linyq
 * @Email: guanghsy2000@163.com
 * @Date: 2020-01-13 10:47:17
 * @Last Modified by: hejk
 * @Last Modified time: 2020-05-11 14:48:06
 * @Description: 管理端 - 项目外 - 申报管理
 */
import View from '@/components/view';

const EnterpriseDeclareAudit = () => import(/* webpackChunkName: "gl-enterprise-declare-audit" */ '@/pages/aup/manage-org/proj-out/declare-manage/enterprise-declare-audit'); // 企业申报初审
const EnterpriseDeclareReAudit = () => import(/* webpackChunkName: "gl-enterprise-declare-re-audit" */ '@/pages/aup/manage-org/proj-out/declare-manage/enterprise-declare-re-audit'); // 企业申报复审
const Audit = () => import(/* webpackChunkName: "gl-enterprise-declare-audit-audit" */ '@/pages/aup/manage-org/proj-out/declare-manage/enterprise-declare-audit/audit'); // 企业申报初审审核
const EnterpriseDetail = () => import(/* webpackChunkName: "yq-enterprise-detail" */ '@/pages/aup/enterprise-org/proj-out/declare-manage/enterprise/detail'); // 企业资料详情
const EnterpriseContrast = () => import(/* webpackChunkName: "gl-enterprise-contrast" */ '@/pages/aup/enterprise-org/proj-out/declare-manage/enterprise/contrast'); // 企业比对查看
const EnterpriseChangeLog = () => import(/* webpackChunkName: "gl-enterprise-change-log" */ '@/pages/aup/enterprise-org/proj-out/declare-manage/enterprise/components/change-log'); // 企业资料变更记录

const ProDeclareAudit = () => import(/* webpackChunkName: "gl-pro-declare-audit" */ '@/pages/aup/manage-org/proj-out/declare-manage/pro-declare-audit'); // 产品申报初审
const ProDeclareReAudit = () => import(/* webpackChunkName: "gl-pro-declare-re-audit" */ '@/pages/aup/manage-org/proj-out/declare-manage/pro-declare-re-audit'); // 产品申报复审
const ProductAudit = () => import(/* webpackChunkName: "gl-pro-declare-audit-audit" */ '@/pages/aup/manage-org/proj-out/declare-manage/pro-declare-audit/audit'); // 产品审核
const ProductDetail = () => import(/* webpackChunkName: "yq-prod-detail" */ '@/pages/aup/enterprise-org/proj-out/declare-manage/product/detail'); // 产品资料详情
const proContrast = () => import(/* webpackChunkName: "gl-prod-contrast" */ '@/pages/aup/enterprise-org/proj-out/declare-manage/product/contrast'); // 产品比对查看


export default [
    {
        path: 'declare-manage',
        component: View,
        meta: {
            title: '申报管理'
        },
        children: [
            {
                path: 'enterprise-declare-audit',
                component: EnterpriseDeclareAudit,
                meta: {
                    title: '企业申报初审'
                }
            },
            {
                path: 'enterprise-declare-recheck',
                component: EnterpriseDeclareReAudit,
                meta: {
                    title: '企业申报复审'
                }
            },
            {
                path: 'enterprise-declare-audit/audit',
                component: Audit,
                meta: {
                    title: '企业资料审核',
                    display: 'none'
                }
            },
            {
                path: 'enterprise-declare-recheck/audit',
                component: Audit,
                meta: {
                    title: '企业资料审核',
                    display: 'none'
                }
            },
            {
                path: 'enterprise-declare-audit/detail',
                component: EnterpriseDetail,
                meta: {
                    title: '企业资料详情',
                    display: 'none'
                }
            },
            {
                path: 'enterprise-declare-recheck/detail',
                component: EnterpriseDetail,
                meta: {
                    title: '企业资料详情',
                    display: 'none'
                }
            },
            {
                path: 'enterprise-declare-audit/change-log/detail',
                component: EnterpriseDetail,
                meta: {
                    title: '企业资料详情',
                    display: 'none'
                }
            },
            {
                path: 'enterprise-declare-recheck/change-log/detail',
                component: EnterpriseDetail,
                meta: {
                    title: '企业资料详情',
                    display: 'none'
                }
            },
            {
                path: 'enterprise-declare-audit/change-log',
                component: EnterpriseChangeLog,
                meta: {
                    title: '变更记录',
                    noTag: true,
                    display: 'none'
                }
            },
            {
                path: 'enterprise-declare-recheck/change-log',
                component: EnterpriseChangeLog,
                meta: {
                    title: '变更记录',
                    noTag: true,
                    display: 'none'
                }
            },
            {
                path: 'enterprise-declare-audit/change-log/contrast',
                component: EnterpriseContrast,
                meta: {
                    title: '企业比对查看',
                    display: 'none'
                }
            },
            {
                path: 'enterprise-declare-recheck/change-log/contrast',
                component: EnterpriseContrast,
                meta: {
                    title: '企业比对查看',
                    display: 'none'
                }
            },
            {
                path: 'pro-declare-audit',
                component: ProDeclareAudit,
                meta: {
                    title: '产品申报初审'
                }
            },
            {
                path: 'pro-declare-recheck',
                component: ProDeclareReAudit,
                meta: {
                    title: '产品申报复审'
                }
            },
            {
                path: 'pro-declare-audit/audit',
                component: ProductAudit,
                meta: {
                    title: '产品资料审核',
                    noTag: true,
                    display: 'none'
                }
            },
            {
                path: 'pro-declare-recheck/audit',
                component: ProductAudit,
                meta: {
                    title: '产品资料审核',
                    noTag: true,
                    display: 'none'
                }
            },
            {
                path: 'pro-declare-audit/detail',
                component: ProductDetail,
                meta: {
                    title: '产品资料详情',
                    noTag: true,
                    display: 'none'
                }
            },
            {
                path: 'pro-declare-recheck/detail',
                component: ProductDetail,
                meta: {
                    title: '产品资料详情',
                    noTag: true,
                    display: 'none'
                }
            },
            {
                path: 'pro-declare-audit/audit/contrast',
                component: proContrast,
                meta: {
                    title: '产品比对查看',
                    noTag: true,
                    display: 'none'
                }
            },
            {
                path: 'pro-declare-recheck/audit/contrast',
                component: proContrast,
                meta: {
                    title: '产品比对查看',
                    noTag: true,
                    display: 'none'
                }
            },
            {
                path: 'pro-declare-audit/audit/detail',
                component: ProductDetail,
                meta: {
                    title: '产品资料详情',
                    noTag: true,
                    display: 'none'
                }
            },
            {
                path: 'pro-declare-recheck/audit/detail',
                component: ProductDetail,
                meta: {
                    title: '产品资料详情',
                    noTag: true,
                    display: 'none'
                }
            }
        ]
    }
];
