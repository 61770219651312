/*
 * @Author: linsw
 * @Email: linsw@hxmec.com
 * @Date: 2019-12-25 15:53:25
 * @Last Modified by: xujh
 * @Last Modified time: 2020-07-09 15:02:21
 * @Description: 配送协议管理-配送协议列表
 */
import View from '@/components/view';

const Index = () => import(/* webpackChunkName: "yq-protocol-manage-index" */ '@/pages/aup/enterprise-org/proj-out/protocol-manage/index');
const Entrust = () => import(/* webpackChunkName: "yq-protocol-manage-entrust" */ '@/pages/aup/enterprise-org/proj-out/protocol-manage/entrust');// 
const Quer = () => import(/* webpackChunkName: "yq-protocol-manage-quer" */ '@/pages/aup/enterprise-org/proj-out/protocol-manage/quer');
const routes = [
    {
        path: 'protocol-manage',
        component: View,
        meta: {
            title: '配送协议管理'
        },
        children: [
            {
                path: 'index',
                component: Index,
                meta: {
                    title: '配送协议签订列表'
                }
            },
            {
                path: 'entrust',
                component: Entrust,
                meta: {
                    title: '委托配送解约确认'
                }
            },
            {
                path: 'quer',
                component: Quer,
                meta: {
                    title: '配送协议查询'
                }
            }
        ]
    }
];

export default routes;
