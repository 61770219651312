/*
 * @Author: zhangzc
 * @Email: zhangzc2@guahao.com
 * @Date: 2019-07-05 16:51:39
 * @Last Modified by: hejk
 * @Last Modified time: 2021-06-08 08:45:59
 * @Description: 公共请求封装
 * @Route: Route
 */
import Axios from 'axios';
import Configs from '@/config';
// import { getUrlParame } from '@/utils/utils';
// import { apiQueryToken } from '@/api/api';

const fetch = async config => {
    const { App } = window;
    const {
        method, params, independent, timeout, ...other
    } = config;
    let { url } = config;
    const jwtData = localStorage.getItem('token'); // 获取token
    // const itemId = getUrlParame('itemId');
    let authorization = '';
    // 未登录
    const authorizations = ['/auth/oauth/token', '/upms/user/caCheckUser', '/auth/mobile/token/social'];

    if (authorizations.indexOf(url) !== -1) {
        authorization = 'Basic aHhtZWM6aHhtZWM=';
    } else {
        authorization = `Bearer ${jwtData}`;
    }
    const headers = {
        Authorization: authorization, // jwtData || ''
        from: 'Y'
        // isToken: false
        // 'Content-Type': 'application/x-www-form-urlencoded'
    };
    const timestamp = new Date().getTime();
    // 转换服务器
    let { baseUrl } = Configs;
    const Osses = Configs.baseOss;
    if (Osses) {
        Object.keys(Osses).forEach(key => {
            const reg = new RegExp(`^${key}`);
            if (url.match(reg)) {
                url = url.replace(reg, '');
                baseUrl = Osses[key];
            }
        });
    }
    // 创建axios实例
    const axiosIns = Axios.create();
    // 获取登录页面的配置信息不能带token,base服务不需要token
    if (url.indexOf('/sysTenant/getUrl') > -1 || url.indexOf('/base/fe/') > -1) {
        delete headers.Authorization;
        // delete headers.from;
    }
    let options = {
        headers,
        baseURL: baseUrl,
        url,
        ...other,
        timeout: timeout || 1 * 60 * 1000
    };
    // 增加请求参数
    const paramObj = params;
    // if (itemId) {
    //     paramObj = {
    //         ...params,
    //         itemId
    //     };
    // } else {
    //     paramObj = params;
    // }

    if (method === 'post') {
        if (url.match(/^\/auth/) !== null) {
            options = Object.assign({}, options, { method: 'post', params: paramObj });
        } else {
            options = Object.assign({}, options, { method: 'post', data: paramObj });
        }
    } else if (method === 'put') {
        options = Object.assign({}, options, { method: 'put', data: paramObj });
    } else if (method === 'delete') {
        options = Object.assign({}, options, { method: 'delete', data: paramObj });
    } else {
        options = Object.assign(
            {},
            options,
            {
                method: 'get',
                params: {
                    ...paramObj,
                    timestamp
                }
            }
        );
    }

    const response = await axiosIns(options).then(res => {
        const { code, msg } = res.data;
        if (code === '99') {
            return 99;
        }
        // independent 存在做单独异常处理，其余走统一业务处理
        if (independent) return res.data;
        if (code !== 0) { // code不为0视为错误
            App.$message({
                type: 'error',
                message: msg,
                showClose: true
            });
            return false;
        }
        if (sessionStorage.getItem('isFirstIn') === null) {
            sessionStorage.setItem('isFirstIn', 'no');
        } 
        return res.data;
    }).catch(err => {
        if (err.response && err.response.status === 401) {
            if (sessionStorage.getItem('isFirstIn') === 'no') {
                App.$message.error(err.response.data.msg || '请重新登录');
            } 
            localStorage.setItem('token', '');
            App.$router.push({
                path: '/login',
                query: {
                    path: App.$route.fullPath
                }
            });
            return false;
        } if (err.response && err.response.status === 426) {
            App.$message({
                type: 'error',
                message: err.response.data.msg,
                showClose: true
            });
            return false;
        } if (err.response && err.response.status === 404) {
            App.$message.error('未找到对应接口');
            return false;
        }
        // 错误内容提示
        const errData = err.response ? err.response.data : '';
        const env = process.env.NODE_ENV;
        // 非开发环境提示 //(-100, "业务异常")(-200, "系统异常")
        if (env !== 'development' && errData && errData.code === -2) {
            // 超时
            App.$message.error('接口请求超时！');
            return false;
        } if (env !== 'development' && errData &&
            (errData.code === -200 || errData.code === -1)) {
            // 系统繁忙
            App.$message.error('系统服务异常');
            return false;
        }
        // 错误内容提示
        if (errData && typeof errData === 'string') {
            App.$message({
                type: 'error',
                message: errData,
                showClose: true
            });
        } else if (errData && typeof errData === 'object' && errData.msg) {
            App.$message({
                type: 'error',
                message: errData.msg,
                showClose: true
            });
        } else {
            App.$message.error('系统异常');
        }

        return false;
    });

    return response;
};

export default fetch;
