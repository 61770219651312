/*
 * @Author: hejk
 * @Email: hejk@hxmec.com
 * @Date: 2019-11-12 11:19:11
 * @Last Modified by: hejk
 * @Last Modified time: 2020-08-07 15:43:38
 * @Description: 企业端-项目内
 * @Route: Route
 */
import View from '@/components/view';
import DeliveryManage from './delivery-manage';
import DirectoryManage from './directory-manage';
import priceLimitPub from './price-limit-pub';
import directNetManage from './direct-net-manage';

const Index = () => import('@/pages/aup/enterprise-org/proj-out/index');


const routes = [
    {
        path: 'proj-inner',
        component: View,
        meta: {
            title: '项目内'
        },
        children: [
            {
                path: 'index',
                component: Index,
                meta: {
                    title: '工作台'
                }
            },
            {
                path: 'directory-management',
                component: View,
                children: require('./directory-management').default,
                meta: {
                    title: '目录管理'
                }
            },
            {
                path: 'amount-management',
                component: View,
                children: require('./amount-management').default,
                meta: {
                    title: '报量管理'
                }
            },
            // {
            //     path: 'knead',
            //     component: View,
            //     children: require('./proj-knead').default,
            //     meta: {
            //         title: '产品捏合'
            //     }
            // },
            {
                path: 'project-registration',
                component: View,
                children: require('./project-registration').default,
                meta: {
                    title: '项目报名'
                }
            },
            {
                path: 'bid-management',
                component: View,
                children: require('./bid-management').default,
                meta: {
                    title: '报价管理'
                }
            },
            {
                path: 'negotiations-management',
                component: View,
                children: require('./negotiations-management').default,
                meta: {
                    title: '谈判管理'
                }
            },
            {
                path: 'competitive-price',
                component: View,
                children: require('./competitive-price').default,
                meta: {
                    title: '竞价管理'
                }
            },
            {
                path: 'select-result',
                component: View,
                children: require('./select-result').default,
                meta: {
                    title: '入选结果公示'
                }
            },
            {
                path: 'distribution-project-manag',
                component: View,
                children: require('./distribution-project-manag').default,
                meta: {
                    title: '配送管理（配送企业）'
                }
            },
            ...DeliveryManage,
            ...DirectoryManage,
            ...priceLimitPub,
            ...directNetManage
        ]
    }
];

export default routes;
