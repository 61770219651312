/*
 * @Author: hejk
 * @Email: hejk@hxmec.com
 * @Date: 2020-03-31 17:02:06
 * @Last Modified by: hejk
 * @Last Modified time: 2021-06-07 20:03:47
 * @Description: 历史采购量管理
 */
import View from '@/components/view';

const PurchaseStatistics = () => import(/* webpackChunkName: "gl-purchase-statistics" */ '@/pages/aup/manage-org/proj-inner/history-purchase-manage/purchase-statistics'); // 历史采购量统计
const PurchaseAudit = () => import(/* webpackChunkName: "gl-purchase-audit" */ '@/pages/aup/manage-org/proj-inner/history-purchase-manage/purchase-audit'); // 历史采购量统计
const Audit = () => import(/* webpackChunkName: "gl-history-audit" */ '@/pages/aup/manage-org/proj-inner/history-purchase-manage/audit'); // 历史采购量统计
const Log = () => import(/* webpackChunkName: "gl-history-log" */ '@/pages/aup/manage-org/proj-inner/history-purchase-manage/log'); // 历史采购量统计
const hisPurLog = () => import(/* webpackChunkName: "gl-history-log" */ '@/pages/aup/manage-org/proj-inner/history-purchase-manage/his-pur-log'); // 历史采购量日志

export default [
    {
        path: 'history-purchase-manage',
        component: View,
        meta: {
            title: '历史采购量管理'
        },
        children: [
            {
                path: 'purchase-audit',
                component: PurchaseAudit,
                meta: {
                    title: '历史采购量审核'
                }
            },
            {
                path: 'purchase-audit/audit',
                component: Audit,
                meta: {
                    title: '审核',
                    display: 'none'
                }
            },
            {
                path: 'purchase-audit/log',
                component: Log,
                meta: {
                    title: '日志',
                    display: 'none'
                }
            },
            {
                path: 'purchase-statistics',
                component: PurchaseStatistics,
                meta: {
                    title: '历史采购量统计'
                }
            },
            {
                path: 'his-pur-log',
                component: hisPurLog,
                meta: {
                    title: '历史采购量日志'
                }
            }
        ]
    }
];
