/*
 * @Author: linyq
 * @Email: guanghsy2000@163.com
 * @Date: 2020-01-13 14:30:59
 * @Last Modified by: hejk
 * @Last Modified time: 2020-05-11 14:46:11
 * @Description: 申报结果公示
 */

import View from '@/components/view';

const EnterpriseIndex = () => import(/* webpackChunkName: "gl-enterprise-declare-result" */ '@/pages/aup/manage-org/proj-out/declare-result/enterprise-declare-result'); // 企业申报公示
const EnterpriseDetail = () => import(/* webpackChunkName: "yq-enterprise-detail" */ '@/pages/aup/enterprise-org/proj-out/declare-manage/enterprise/detail'); // 企业资料详情

const ProductIndex = () => import(/* webpackChunkName: "gl-pro-declare-result" */ '@/pages/aup/manage-org/proj-out/declare-result/pro-declare-result'); // 产品申报公示 
const ProductDetail = () => import(/* webpackChunkName: "yq-prod-detail" */ '@/pages/aup/enterprise-org/proj-out/declare-manage/product/detail'); // 产品资料详情

export default [
    {
        path: 'declare-result',
        component: View,
        meta: {
            title: '申报结果'
        },
        children: [
            {
                path: 'enterprise',
                component: EnterpriseIndex,
                meta: {
                    title: '企业申报公示'
                }
            },
            {
                path: 'enterprise/detail',
                component: EnterpriseDetail,
                meta: {
                    title: '企业信息',
                    noTag: true,
                    display: 'none'
                }
            },
            {
                path: 'product',
                component: ProductIndex,
                meta: {
                    title: '产品申报公示'
                }
            },
            {
                path: 'product/detail',
                component: ProductDetail,
                meta: {
                    title: '产品详情',
                    noTag: true,
                    display: 'none'
                }
            }
            
        ]
    }
];
