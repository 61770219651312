/*
 * @Author: hejk
 * @Email: hejk@hxmec.com
 * @Date: 2019-12-24 10:08:07
 * @Last Modified by: xujh
 * @Last Modified time: 2020-06-09 18:04:17
 * @Description: 申报系统api
 */
// 参数配置初始化
export const paramConfigList = '/aup/api/aup/param/config/list';
// 保存参数配置
export const paramConfig = '/aup/api/aup/param/config';
// 耗材分类
export const catalogPage = '/aup/api/aup/catalog/page';
// 删除耗材分类
export const catalogRemove = '/aup/api/aup/catalog/remove';
// 耗材分类修改记录
export const catalogLogPage = '/aup/api/aup/catalog_log/page';
// 新增耗材分类
export const catalogCreate = '/aup/api/aup/catalog/create';
// 查询耗材目录基础表
export const catalogDetail = '/aup/api/aup/catalog';
// 查询耗材分类
export const catalogClass = '/aup/api/aup/catalog/class';
// 修改耗材目录基础表
export const catalogUpdate = '/aup/api/aup/catalog/update';
// 查询分类目录
export const catalogClassified = '/aup/api/aup/catalog/classified';
// 初始耗材目录树
export const catalogTree = '/aup/api/aup/catalog/tree';
// 产品库和变更记录的产品详情
export const auditInfoGet = '/aup/api/aup/prod/audit/info/get';
// 产品审核
export const auditInfoModify = '/aup/api/aup/prod/audit/info/modify';
// 产品审核列表
export const auditInfoPage = '/aup/api/aup/prod/audit/info/page';
// 产品批量审核
export const auditInfoBatchModify = '/aup/api/aup/prod/audit/info/batch/modify';
// 企业审核
export const auditOptAudit = '/aup/api/aup/org/audit/info/opt/audit';
// 企业未审核列表
export const auditPageAuditing = '/aup/api/aup/org/audit/info/page/auditing';
// 企业已审核列表
export const auditPageAudited = '/aup/api/aup/org/audit/info/page/audited';
// 企业端重新审核不通过
export const orgAuditReexamine = '/aup/api/aup/org/audit/opt/reexamine';
// 国家统一编码
export const nationalCodeGet = '/aup/api/aup/national/code/get';
// 产品详情
export const prodBaseInfoGet = '/aup/api/aup/prod/base/info/get';
// 单一规格列表
export const singleStandardPage = '/aup/api/aup/prod/base/single/standard/page';
// 单一规格审核列表-变更记录
export const auditSingleStandardPage = '/aup/api/aup/prod/audit/single/standard/page';
// 单一规格比对
export const singleStandardCompare = '/aup/api/aup/prod/audit/single/standard/compare/last';
// 注册证详情
export const registerCardGet = '/aup/api/aup/prod/base/register/card/get';
// 产品库和变更记录的注册证详情
export const auditRegisterCardGet = '/aup/api/aup/prod/audit/info/get/reg/card';
// 注册证新增
export const registerCardAdd = '/aup/api/aup/prod/base/register/card/add';
// 注册证修改
export const registerCardModify = '/aup/api/aup/prod/base/register/card/modify';
// 注册证修改并提交
export const registerCardSaveAndSubmit = '/aup/api/aup/prod/base/register/card/saveAndSubmit';
// 注册证比对
export const regCardCompareLast = '/aup/api/aup/prod/audit/info/compare/last/reg/card';
// 注册证总体比对
export const regCardCompareFirst = '/aup/api/aup/prod/audit/info/compare/first/reg/card';
// 产品删除
export const prodInfoDelete = '/aup/api/aup/prod/base/info/delete';
// 产品基础列表
export const prodBaseInfoPage = '/aup/api/aup/prod/base/info/page';
// 导入单一规格
export const singleStandardImport = '/aup/api/aup/prod/base/single/standard/excel/import';
// 批量删除单一规格
export const singleStandardBatchDelete = '/aup/api/aup/prod/base/single/standard/batch/delete';
// 单一规格添加
export const singleStandardAdd = '/aup/api/aup/prod/base/single/standard/add';
// 单一规格修改
export const singleStandardModify = '/aup/api/aup/prod/base/single/standard/modify';
// 单一规格查询
export const singleStandardGet = '/aup/api/aup/prod/base/single/standard/get';
// 产品添加
export const prodBaseInfoAdd = '/aup/api/aup/prod/base/info/add';
// 产品修改
export const prodBaseInfoModify = '/aup/api/aup/prod/base/info/modify';
// 产品删除
export const singleStandardDelete = '/aup/api/aup/prod/base/single/standard/delete';
// 产品比对
export const prodInfoCompareLast = '/aup/api/aup/prod/audit/info/compare/last';
// 产品总体比对
export const prodInfoCompareFirst = '/aup/api/aup/prod/audit/info/compare/first';
// 产品变更记录
export const prodInfoChangeList = '/aup/api/aup/prod/audit/info/page/change/list';
// 产品提交审核
export const prodBaseInfoSubmit = '/aup/api/aup/prod/base/info/submit';
// 产品撤回
export const prodBaseInfoRevocat = '/aup/api/aup/prod/base/info/revocat';
// 产品基础资料列表
export const prodBaseRegisterCardPage = '/aup/api/aup/prod/base/register/card/page';
// 产品库
export const prodAuditPageData = '/aup/api/aup/prod/audit/info/page/product/data';
// 企业比对
export const orgAuditOptCompare = '/aup/api/aup/org/audit/info/opt/compare';
// 企业审核比对
export const orgAuditGetCompare = '/aup/api/aup/org/audit/info/get/audit';
// 企业基础信息
export const orgBaseInfoGet = '/aup/api/aup/org/base/get/Info';
// 管理端企业详情
export const orgAuditInfoGet = '/aup/api/aup/org/audit/info/get/info';
// 保存企业信息
export const orgBaseInfoUpdate = '/aup/api/aup/org/base/update/info';
// 提交企业信息
export const orgBaseInfoSubmit = '/aup/api/aup/org/base/submit/info';
// 企业信息撤回审核
export const orgBaseInfoRevoke = '/aup/api/aup/org/base/revoke/info';
// 历次信息-企业端
export const orgBasePageEvents = '/aup/api/aup/org/base/page/events';
// 历次信息-管理端
export const orgAuditPageEvents = '/aup/api/aup/org/audit/info/page/events';
// 区域信息
export const baseAreaList = '/aup/api/aup/base/area/listArea';
// 提交注册证判断
export const prodRegisterSubmit = '/aup/api/aup/prod/base/register/card/submit';
// 产品列表-选择分类
export const catalogPageData = '/aup/api/aup/catalog/page/data';
// 产品重新审核
export const prodInfoReAudit = '/aup/api/aup/prod/audit/info/reAudit';
// 企业申报公示列表
export const orgDataDeclareList = '/aup/api/aup/org/data/info/page';
// 企业申报信息详情
export const orgDataDeclareDetail = '/aup/api/aup/org/data/info/get';
// 产品申报公示列表
export const prodDataDeclareList = '/aup/api/aup/prod/data/info/page';
// 产品申报信息详情
export const prodDataDeclareDetail = '/aup/api/aup/prod/data/info/get';
// 产品申报注册证详情
export const prodDataDeclareCardDetail = '/aup/api/aup/prod/data/info/get/reg/card';
// 产品申报单一规格列表
export const prodDataDeclareSingle = '/aup/api/aup/prod/data/single/standard/page';
// 企业资料复审-待审核
export const auditPageReAuditing = '/aup/api/aup/org/audit/info/page/re/auditing';
// 企业资料复审-已审核
export const auditPageReAudited = '/aup/api/aup/org/audit/info/page/re/audited';
// 企业复核
export const auditOptReAudit = '/aup/api/aup/org/audit/info/opt/re/audit';
// 产品复审
export const prodReAuditList = '/aup/api/aup/prod/audit/info/second/audit/page';
// 产品审核
export const auditInfoReAudit = '/aup/api/aup/prod/audit/info/sendcond/audit';
// 产品-单一规格维护-批量删除
export const singleStandardBatchDeleteRelation = '/aup/api/aup/prod/base/single/standard/batch/delete/relation';
// 产品-单一规格维护-删除
export const singleStandardDeleteRelation = '/aup/api/aup/prod/base/single/standard/delete/relation';
// 产品-单一规格维护-批量添加
export const singleStandardBatchRelation = '/aup/api/aup/prod/base/single/standard/batch/relation';
// 产品库-产品变更记录
export const productChangeList = '/aup/api/aup/prod/audit/info/prod/change/list';
// 选择比对-产品
export const prodInfoCompare = '/aup/api/aup/prod/audit/info/compare/prod';
// 选择比对-注册证
export const regCardCompare = '/aup/api/aup/prod/audit/info/compare/reg/card';
// 产品库-产品详情
export const prodDataInfoGet = '/aup/api/aup/prod/data/info/get';
// 产品库-注册证详情
export const prodRegCardInfo = '/aup/api/aup/prod/data/info/get/reg/card';
// 产品库-单一规格
export const prodSingleStandardPage = '/aup/api/aup/prod/data/single/standard/page';
// 医保医用耗材分类代码列表
export const nationalCodePage = '/aup/api/aup/national/code/page';
// 单一规格列表-审核页面
export const singleStandardPageData = '/aup/api/aup/prod/audit/single/standard/page/data';
// 
export const prodDataInfoPage = '/aup/api/aup/prod/data/info/page';
