/*
 * @Author: linyq
 * @Email: guanghsy2000@163.com
 * @Date: 2020-01-19 14:52:23
 * @Last Modified by: hejk
 * @Last Modified time: 2020-05-11 14:55:17
 * @Description: 竞价管理
 */

import View from '@/components/view';
import fetch from '@/utils/fetch';

const biddingManage = () => import(/* webpackChunkName: "gl-bidding-manage-index" */ '@/pages/aup/manage-org/proj-inner/bidding-manage/index');
const biddingResult = () => import(/* webpackChunkName: "gl-bidding-manage-result" */ '@/pages/aup/manage-org/proj-inner/bidding-manage/result');
const scheduleCatalogue = () => import(/* webpackChunkName: "gl-bidding-manage-schedule" */ '@/pages/aup/manage-org/proj-inner/bidding-manage/schedule-catalogue');
const scheduleProduct = () => import(/* webpackChunkName: "gl-bidding-manage-schedule" */ '@/pages/aup/manage-org/proj-inner/bidding-manage/schedule-product');

const routes = [
    {
        path: 'bidding',
        component: View,
        meta: {
            title: '竞价管理'
        },
        children: [
            {
                path: 'manage',
                component: biddingManage,
                meta: {
                    title: '竞价解密-已开始',
                    display: 'none'
                }
            },
            {
                path: 'decrypt-before',
                component: () => import('@/pages/common/notice'),
                meta: {
                    title: '竞价解密'
                },
                beforeEnter(to, from, next) {
                    fetch({
                        url: '/aup/api/aup/project/param/round/config/get_round_status',
                        method: 'post',
                        params: {
                            projectId: to.query.itemId || 1,
                            configType: 1
                        }
                    }).then(res => {
                        const { startTime, endTime, status, roundNum } = res.data;
                        to.query.startTime = startTime;
                        to.query.endTime = endTime;
                        if (status === 0) {
                            to.query.title = '竞价解密环节未开始，请耐心等待！';
                            next();
                        } else if (status === 3) {
                            to.query.title = '竞价解密环节已结束！';
                            next();
                        } else if (status === 2) {
                            to.query.title = '竞价解密环节已暂停，请耐心等待！';
                        } else {
                            next({
                                path: '/aup/manage-org/proj-inner/bidding/manage',
                                query: Object.assign({ roundNum }, from.query)
                            });
                        }
                    });
                }
            },
            {
                path: 'result',
                component: biddingResult,
                meta: {
                    title: '竞价结果管理'
                }
            },
            {
                path: 'schedule-catalogue',
                component: scheduleCatalogue,
                meta: {
                    title: '竞价进度管理-目录维度'
                }
            },
            {
                path: 'schedule-product',
                component: scheduleProduct,
                meta: {
                    title: '竞价进度管理-产品维度'
                }
            }
        ]
    }
];

export default routes;
