/*
 * @Author: hejk
 * @Email: hejk@hxmec.com
 * @Date: 2019-11-13 10:52:19
 * @Last Modified by: hejk
 * @Last Modified time: 2021-04-28 11:38:10
 * @Description: 企业资料
 */

<template>
    <div class="qualificationInfo-box">
        <el-tabs ref="qualificationInfo"
                 v-model="activeName"
                 tab-position="left"
                 @tab-click="handleClick">
            <el-tab-pane v-for="item in outConfigArray"
                         :key="item.name"
                         :name="item.name">
                <div slot="label">
                    <img v-if="item.exprieFlag"
                         src="~@/images/timeout-icon.png">
                    <p>
                        <span v-if="item.qualiType === 1"
                              class="is-required-icon">*</span>{{ item.name }}
                    </p>
                </div>
                <div class="title">
                    {{ item.name }}
                </div>
                <div class="tips">
                    <div v-if="item.tips">
                        <div class="tip-title">
                            温馨提示：
                        </div>
                        <div class="tip-content"
                             v-html="item.tips" />
                    </div>
                </div>
                <bk-form ref="dynamicConfig"
                         :form-config="item.config" />
            </el-tab-pane>
        </el-tabs>
        <catalogueDialog v-if="openCatalogue"
                         ref="catalogue"
                         :results="results"
                         :catalogue-name="catalogueName"
                         :catalogue-type="catalogueType"
                         @closeCata="closeCata"
                         @changeCata="changeCata" />
    </div>
</template>

<script>
import { dateFormatConversion } from '@/utils/utils';
import catalogueDialog from './catalogue-dialog';

export default {
    components: {
        catalogueDialog
    },
    props: {
        deploy: {
            type: Array,
            default: () => []
        },
        results: {
            type: Object,
            default: () => ({})
        },
        isEdit: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            activeName: '',
            outConfigArray: [],
            activeIndex: 0,
            businessLicenseDateStart: '',
            businessLicenseDateEnd: '',
            catalogueName: '',
            catalogueType: 1, // 1:原分类目录  2:新分类目录
            openCatalogue: false

        };
    },
    watch: {
        deploy: {
            handler(val) {
                // 部署动态模版配置渲染组件
                this.doRender(val);
            },
            deep: true,
            immediate: true
        },
        isEdit: {
            handler(val) {
                this.doEditableChange(val);
            },
            deep: true,
            immediate: true
        },
        results: {
            handler(val) {
                if (!val || Object.keys(val).length < 1) return;
                this.doDataRender(val);
            },
            deep: true,
            immediate: true
        }
    },
    methods: {
        /**
         * 检验单个表单是否存在必填项未填写
         */
        itemValidate(item, index) {
            let handleFlag = true;
            item.handleVerify(() => {
            }, () => {
                const { name } = this.outConfigArray[index];
                this.$message.error(`${name.split('（已过期）')[0]}存在必填项未填写`);
                this.activeName = name;
                handleFlag = false;
            });
            return handleFlag;
        },

        /**
         * 必填校验
         */
        validate() {
            let outNumber = 0;
            let validateFlag = true;
            const dynamics = this.$refs.dynamicConfig || [];
            for (let i = 0; i < dynamics.length; i += 1) {
                const item = dynamics[i];
                const handleFlag = this.itemValidate(item, i);
                if (!handleFlag) {
                    break;
                } else {
                    outNumber += 1;
                }
            }
            validateFlag = outNumber === dynamics.length;
            return validateFlag;
        },
        /**
         * 获取参数
         */
        getParams() {
            const dynamicArr = this.$refs.dynamicConfig;
            let dynamicParams = {};
            dynamicArr.forEach(item => {
                dynamicParams = Object.assign({}, dynamicParams, { ...item.formatFormParams() });
            });
            
            const fieldList = this.getFieldParams(dynamicParams);
            return fieldList;
        },
        /**
         * 拼接动态资质参数
         * @param {Object} dynamicParams
         */
        getFieldParams(dynamicParams) {
            const { deploy } = this;
            const paramList = [];
            deploy.forEach(item => {
                if (!item.data) {
                    return;
                }
                item.data.forEach(subItem => {
                    let val = dynamicParams[subItem.fieldName] || '';
                    if (Array.isArray(val) && subItem.fieldValueType === 8) {
                        val = val.join('至');
                    }
                    // 数据回转
                    if (val.match(/\//g)) {
                        val = val.replace(/\//g, '-');
                    }
                    const param = {
                        id: subItem.id,
                        inputDefaultValue: val
                    };
                    paramList.push(param);
                });
            });
            return paramList;
        },
        /**
         * 切合tab显示tab对象
         * @param {Object} tab 
         */
        handleClick(tab) {
            this.activeIndex = tab.index;
            this.activeName = tab.name;
        },
        /**
         * 根据返回得数据，动态生成资质模版
         * @param {Object} arr 返回的配置项数组
         */
        doRender(arr) {
            const allConfig = [];
            arr.forEach(item => {
                const form = {};
                form.name = item.fieldLable;
                const formlist = [];
                const keyVal = this.results || {};
                if (!item.data) {
                    return;
                }
                item.data.forEach(subItem => {
                    const formItem = {};
                    let beforeMsg = '请选择';
                    let trigger = 'blur';
                    if (!this.results) {
                        keyVal[subItem.fieldName] = subItem.inputDefaultValue;
                    }
                    if (subItem.fieldValueType === 7) {
                        form.tips = subItem.inputDefaultValue.replace(/。/g, '。<br/>');
                    } else if (subItem.fieldValueType !== 10) {
                        formItem.label = subItem.fieldLable;
                        formItem.name = subItem.fieldName;
                        formItem.value = subItem.inputDefaultValue || '';
                        // 表单项合成 
                        if (subItem.fieldValueType === 1) {
                            formItem.type = 'input';
                            beforeMsg = '请输入';
                            formItem.extend = {
                                maxlength: 100
                            };
                        } else if (subItem.fieldValueType === 2) {
                            trigger = 'change';
                            formItem.type = 'radio';
                            formItem.options = [
                                {
                                    label: '是',
                                    value: '1'
                                },
                                {
                                    label: '否',
                                    value: '0'
                                }
                            ];
                        } else if (subItem.fieldValueType === 3) {
                            formItem.type = 'date';
                            if (subItem.fieldName === 'businessLicenseDateStart') {
                                formItem.extend = this.getTimeExtend(this.results, 'businessLicenseDateStart', 'businessLicenseDateEnd', 1);
                            }
                            if (subItem.fieldName === 'businessLicenseDateEnd') {
                                formItem.extend = this.getTimeExtend(this.results, 'businessLicenseDateEnd', 'businessLicenseDateStart', 2);
                            }
                            if (subItem.fieldName === 'dl_gsp_dateStart') {
                                formItem.extend = this.getTimeExtend(this.results, 'dl_gsp_dateStart', 'dl_gsp_dateEnd', 1);
                            }
                            if (subItem.fieldName === 'dl_gsp_dateEnd') {
                                formItem.extend = this.getTimeExtend(this.results, 'dl_gsp_dateEnd', 'dl_gsp_dateStart', 2);
                            }
                        } else if (subItem.fieldValueType === 4) {
                            formItem.type = 'upload';
                            trigger = 'change';
                            formItem.extend = {
                                multiple: false,
                                limit: 10,
                                headers: {
                                    from: 'Y',
                                    name: 'file'
                                },
                                accept: '.pdf'
                            };
                            formItem.maxSize = 5;
                            formItem.uploadBefore = file => {
                                const flag = this.judgeFile(file);
                                return flag;
                            };
                        } else if (subItem.fieldValueType === 5) {
                            formItem.type = 'textarea';
                            beforeMsg = '请输入';
                            formItem.extend = {
                                maxlength: 100
                            };
                        } else if (subItem.fieldValueType === 6) {
                            formItem.type = 'checkbox';
                        } else if (subItem.fieldValueType === 8) {
                            formItem.type = 'daterange';
                            formItem.value = formItem.value.split('至');
                        } else if (subItem.fieldValueType === 9) {
                            let btnLabel = '原目录分类';
                            let cataType = 1;
                            if (subItem.fieldName.indexOf('newClass') > -1) {
                                btnLabel = '新目录分类';
                                cataType = 2;
                            }
                            formItem.button = {
                                visible: this.isEdit,
                                name: `btn-${subItem.fieldName}`,
                                label: btnLabel,
                                extend: {
                                    type: 'primary'
                                },
                                callback: () => {
                                    this.openCatalogue = true;
                                    this.catalogueName = subItem.fieldName;
                                    this.catalogueType = cataType;
                                }
                            };
                        }

                        // 必填
                        if (subItem.mustFillFlag) {
                            formItem.rules = [
                                {
                                    required: true,
                                    trigger,
                                    message: `${beforeMsg + subItem.fieldLable}`
                                }
                            ];
                        }
                        // 正则校验
                        if (subItem.inputFormatRegular) {
                            const { rules = [] } = formItem;
                            formItem.rules = [
                                ...rules,
                                {
                                    trigger,
                                    validator: (rule, value, callback) => {
                                        if (!value.match(subItem.inputFormatRegular)) {
                                            callback(new Error(`${beforeMsg}正确的${subItem.fieldLable}`));
                                        } else {
                                            callback();
                                        }
                                    }
                                }
                            ];
                        }
                        // 联动判断
                        if (subItem.inputFormatLinkage) {
                            const depend = JSON.parse(subItem.inputFormatLinkage);
                            formItem.depend = {
                                name: depend.name,
                                onChange: (val, that) => {
                                    if (depend.visible) {
                                        that.formConfig.data[0].data[depend.index].visible = !(val === depend.value);
                                        that.formConfig.data[0].data[depend.index].rules[0].required = !(val === depend.value);
                                    }
                                    if (depend.readonly) {
                                        that.formConfig.data[0].data[depend.index].readonly = val === depend.value;
                                    }
                                    if (depend.disabled) {
                                        that.formConfig.data[0].data[depend.index].disabled = val === depend.value;
                                    }
                                }
                            };
                            if (depend.visible) {
                                formItem.visible = !(keyVal[depend.name] === depend.value);
                                formItem.rules[0].required = !(keyVal[depend.name] === depend.value);
                            } else if (depend.disabled) {
                                formItem.disabled = keyVal[depend.name] === depend.value;
                            } else if (depend.readonly) {
                                formItem.readonly = keyVal[depend.name] === depend.value;
                            }
                        }
                        // 是否可编辑
                        if (!subItem.inputFormatEnable) {
                            formItem.disabled = true;
                        }
                        formlist.push(formItem);
                    }
                });
                const formConfig = {
                    readonly: !this.isEdit,
                    data: [
                        {
                            data: formlist
                        }
                    ],
                    results: this.results
                };
                form.exprieFlag = item.exprieFlag;
                form.qualiType = item.qualiType;
                form.config = formConfig;
                allConfig.push(form);
            });
            this.outConfigArray = allConfig;
            if (this.outConfigArray.length) {
                this.activeName = this.outConfigArray[0].name;
            }
        },
        /**
         * 设置可编辑状态
         */
        doEditableChange(val) {
            this.$nextTick(() => {
                const adynamic = this.$refs.dynamicConfig || [];
                // 设置readonly的值
                this.changeReadOnlyStatus([adynamic], !val);
            });
        },
        /**
         * 改变各个form表单的可编辑状态
         * param {Array} arr: 二维数组
         * param {Boolean} flag: 需要设置给readonly的标识位
         */
        changeReadOnlyStatus(arr, flag) {
            arr.forEach(item => {
                if (Array.isArray(item) && item.length) {
                    item.forEach(subItem => {
                        subItem.formConfig.readonly = flag;
                        subItem.formConfig.data[0].data.forEach(threeItem => {
                            if (threeItem.button) {
                                threeItem.button.visible = !flag;
                            }
                        });
                    });
                }
            });
        },
        /**
         * 回填各个template中的数据
         * @param {Object} val 返回的各项信息对象（entpDclaDTO对象）
         */
        doDataRender(val) {
            this.$nextTick(() => {
                for (let i = 0; i < this.outConfigArray.length; i += 1) {
                    this.outConfigArray[i].config.results = val; 
                }
            });
        },
        /**
         * 上传校验
         */
        judgeFile(file) {
            const type = file.name.split('.')[1];
            if (type.toLowerCase() !== 'pdf') {
                this.$message.error('只能上传pdf格式文件');
                return false;
            }
            return true;
        },
        /**
         * 时间继承
         * getTimeExtend
         * @param {Object} results 各项内容值
         * @param {String} name 表单项英文name
         * @param {String} linkName 关联表单项英文name
         * @param {Number} type 类型 1：开始时间  2：结束时间
         */
        getTimeExtend(results, name, linkName, type) {
            this[name] = this.results[name] || '';
            const extend = {
                change: val => {
                    this[name] = dateFormatConversion(val);
                },
                'picker-options': {
                    disabledDate: time => {
                        if (this[linkName]) {
                            if (type === 1) {
                                return time.getTime() >= new Date(this[linkName]).getTime();
                            }
                            return time.getTime() <= new Date(this[linkName]).getTime();
                        }
                        return false;
                    }
                }
            };
            return extend;
        },
        /**
         * 关闭目录分类弹窗
         */
        closeCata() {
            this.openCatalogue = false;
        },
        /**
         * 更新目录分类弹窗值
         */
        changeCata(name, data) {
            this.closeCata();
            const { dynamicConfig } = this.$refs;
            for (let i = 0; i < dynamicConfig.length; i += 1) {
                const param = dynamicConfig[i].formatFormParams();
                this.results = Object.assign(this.results, param);
            }
            this.results[name] = data;
        }
    }
};
</script>

<style lang="less" scoped>
    .qualificationInfo-box {
        border: 1px solid @--border-color-light;
        /deep/ .el-tabs {
            padding: 0;
        }
        /deep/ .el-tabs__item {
            width: 240px;
            height: auto;
            text-align: right;
            line-height: 20px;
            border-bottom: 1px solid @--border-color-light;
            background-color: @--background-color-base;
            font-size: 0;
            white-space: pre-line;
            word-break: break-all;
            word-wrap: break-word;
            padding: 7px 12px;
            position: relative;
            div {
                display: inline-block;
                font-size: 12px;
                line-height: 20px;
                text-align: right;
                vertical-align: middle;
                width: 100%;
            }
            &:last-child {
                border-bottom: none;
            }
            img {
                float: left;
                width: 12px;
                position: relative;
                top: 4px;
                left: -7px;
            }
        }
        /deep/ .is-active {
            background-color: @--color-white;
        }
        /deep/ .el-tab-pane {
            .title {
                height: 48px;
                line-height: 48px;
                padding-left: 25px;
                color: @--color-text-regular;
                background-color: @--background-color-base;
            }
            .tips {
                height: auto;
                padding: 15px 0;
                display: table;
                line-height: 20px;
                width: 100%;
                .tip-title {
                    color: @--color-danger;
                    display: table-cell;
                    vertical-align: middle;
                    float: left;
                }
                .tip-content {
                    float: left;
                    max-width: 90%;
                }
            }
        }
        .is-required-icon {
            color: @--color-danger;
        }
    }
</style>
