/*
 * @Author: linyq
 * @Email: guanghsy2000@163.com
 * @Date: 2019-11-12 19:17:03
 * @Last Modified by: shuying.zheng
 * @Last Modified time: 2022-07-11 17:32:53
 * @Description: 耗材联采通用
 */

import fnMixins from '@/mixins/fn-config';
import { catalogClass } from '@/api/audit';

const enumsUrl = '/aup/api/aup/base/enums/query_enums';
const expertMenu = key => ({
    url: '/aup/api/aup/base/dictionary/getLableByGroupKey',
    params: {
        groupKey: key
    },
    onSuccess: res => {
        res.data.unshift({
            labelName: '全部',
            labelCode: ''
        });
        return res.data;
    }
});
const expertProps = () => ({
    props: {
        label: 'labelName',
        value: 'labelCode'
    }
});

export default {
    mixins: [fnMixins],
    data() {
        return {

        };
    },
    methods: {
        searchStr() {
            return {
                国家: {
                    name: 'countryId',
                    label: '国家',
                    type: 'search',
                    grid: 3,
                    http: {
                        url: '',
                        params: {
                            default: 'name'
                        }
                    }
                },
                省份: {
                    name: 'provinceId',
                    label: '省份',
                    type: 'search',
                    grid: 3
                },
                城市: {
                    name: 'cityId',
                    label: '城市',
                    type: 'search',
                    grid: 3
                },
                '区/县': {
                    name: 'districtId',
                    label: '区/县',
                    type: 'search',
                    grid: 3
                },
                街道: {
                    name: 'streetId',
                    label: '街道',
                    type: 'search',
                    grid: 3
                },
                项目编号: {
                    name: 'projectCode',
                    label: '项目编号',
                    type: 'input',
                    grid: 3
                },
                项目日期: {
                    name: 'projectDate',
                    label: '项目日期',
                    type: 'daterange',
                    extraFields: ['projectStartTime', 'projectEndTime'],
                    grid: 3
                },
                项目全称: {
                    name: 'projectFullName',
                    label: '项目全称',
                    type: 'input',
                    grid: 3
                },
                所属项目: {
                    name: 'projectId',
                    label: '所属项目',
                    type: 'search',
                    grid: 3
                },
                项目简称: {
                    name: 'projectName',
                    label: '项目简称',
                    type: 'input',
                    grid: 3
                },
                项目状态: {
                    name: 'projectStatus',
                    label: '项目状态',
                    type: 'select',
                    grid: 3,
                    options: [
                        {
                            label: '未启动',
                            value: 1
                        },
                        {
                            label: '执行中',
                            value: 2
                        },
                        {
                            label: '已结束',
                            value: 3
                        }
                    ]
                },
                目录编号: {
                    name: 'catalogNum',
                    label: '目录编号',
                    type: 'input',
                    grid: 3
                },
                目录名称: {
                    name: 'catalogName',
                    label: '目录名称',
                    type: 'input',
                    grid: 3
                },
                目录名称1: {
                    name: 'prodCatalogName',
                    label: '目录名称',
                    type: 'input',
                    grid: 3
                },
                产品名称: {
                    name: 'productName',
                    label: '产品名称',
                    type: 'input',
                    grid: 3
                },
                注册证编号: {
                    name: 'cardNo',
                    label: '注册证编号',
                    type: 'input',
                    grid: 3
                },
                注册证名称: {
                    name: 'cardName',
                    label: '注册证名称',
                    type: 'input',
                    grid: 3
                },
                生产企业: {
                    name: 'produceOrg',
                    label: '生产企业',
                    type: 'input',
                    grid: 3
                },
                产品转换比: {
                    name: '产品转换比',
                    label: '产品转换比',
                    type: 'input',
                    grid: 3
                },
                最小使用单位: {
                    name: 'minUnit',
                    label: '最小使用单位',
                    type: 'input',
                    grid: 3
                },
                规格: {
                    name: 'spec',
                    label: '规格',
                    type: 'input',
                    grid: 3
                },
                型号: {
                    name: 'model',
                    label: '型号',
                    type: 'input',
                    grid: 3
                },
                入选状态: {
                    name: '入选状态',
                    label: '入选状态',
                    type: 'select',
                    grid: 3,
                    options: [
                        {
                            label: '全部',
                            value: ''
                        },
                        {
                            label: '入选',
                            value: 1
                        },
                        {
                            label: '淘汰',
                            value: 0
                        }
                    ]
                },
                比较运算符: {
                    name: 'compareStatus',
                    type: 'select',
                    label: '比较运算符',
                    options: [
                        {
                            label: '等于',
                            value: 1
                        },
                        {
                            label: '大于等于',
                            value: 2
                        },
                        {
                            label: '小于等于',
                            value: 3
                        }
                    ]
                },
                片区: {
                    name: 'area',
                    label: '片区',
                    type: 'search',
                    grid: 3
                },
                医保局: {
                    name: 'belongOrgId',
                    label: '医保局',
                    type: 'search',
                    grid: 3
                },
                医疗机构名称: {
                    name: 'hospName',
                    label: '医疗机构名称',
                    type: 'input',
                    grid: 3
                },
                医疗机构编码: {
                    name: 'hospCode',
                    label: '医疗机构编码',
                    type: 'input',
                    grid: 3
                },
                状态: {
                    name: 'auditStatus',
                    label: '状态',
                    type: 'select',
                    grid: 3
                },
                审核不通过原因: {
                    name: 'failedReason',
                    label: '审核不通过原因',
                    type: 'input',
                    grid: 3
                },
                类别: {
                    name: '类别',
                    label: '类别',
                    type: 'input',
                    grid: 3
                },
                提交人: {
                    name: 'commitUser',
                    label: '提交人',
                    type: 'input',
                    grid: 3
                },
                审核状态: {
                    name: 'auditStatus',
                    label: '审核状态',
                    type: 'select',
                    grid: 3,
                    options: [
                        {
                            label: '待审核',
                            value: '0'
                        },
                        {
                            label: '审核通过',
                            value: '1'
                        },
                        {
                            label: '审核不通过',
                            value: '2'
                        }
                    ]
                },
                提交时间: {
                    name: 'commitDate',
                    label: '提交时间',
                    type: 'daterange',
                    grid: 3,
                    extraFields: ['time1', 'time2'],
                    noSubmit: true
                },
                汇总类型: {
                    name: 'gatherType',
                    label: '汇总类型',
                    type: 'select',
                    grid: 3,
                    options: [
                        {
                            label: '按省份汇总',
                            value: '1'
                        },
                        {
                            label: '按片区汇总',
                            value: '2'
                        },
                        {
                            label: '按医保局汇总',
                            value: '3'
                        }
                    ]
                },
                只显示未填报记录: {
                    name: 'onlyShowsNotFillingRecords',
                    label: '只显示未填报记录',
                    type: 'select',
                    grid: 2,
                    options: [
                        {
                            label: '全部',
                            value: ''
                        },
                        {
                            label: '是',
                            value: true
                        },
                        {
                            label: '否',
                            value: false
                        }
                    ]
                },
                企业名称: {
                    name: 'orgInfoName',
                    label: '企业名称',
                    type: 'input',
                    grid: 3
                },
                报名企业: {
                    name: 'enrolledOrgName',
                    label: '报名企业',
                    type: 'input',
                    grid: 3
                },
                最小计量单位: {
                    name: 'minUnit',
                    label: '最小计量单位',
                    type: 'input',
                    grid: 3
                },
                最小包装单位: {
                    name: 'packUnit',
                    label: '最小包装单位',
                    type: 'input',
                    grid: 3
                },
                区域: {
                    name: 'areaName',
                    label: '区域',
                    type: 'input',
                    grid: 3
                },
                历史采购量: {
                    name: 'hisProcureQuantity',
                    label: '历史采购量(按最小计量单位计)',
                    type: 'input'
                },
                约定采购量: {
                    name: 'agreeProcureQuantity',
                    label: '约定采购量(按最小计量单位计)',
                    type: 'input'
                },
                可报价产品数: {
                    name: 'enrolledProd',
                    label: '可报价产品数',
                    type: 'input'
                },
                已报价产品数: {
                    name: 'enrollRoundQuotation',
                    label: '已报价产品数',
                    type: 'input'
                },
                已解密产品数: {
                    name: 'enrollQuotationDecrypt',
                    label: '已解密产品数',
                    type: 'input'
                },
                填报进度: {
                    name: 'filledCatalogNum',
                    label: '填报进度',
                    type: 'input'
                },
                医疗机构: {
                    name: 'hospName',
                    label: '医疗机构',
                    type: 'input'
                },
                填报机构数: {
                    name: 'hospCnt',
                    label: '填报机构数',
                    type: 'input'
                },
                挂网价状态: {
                    name: '挂网价状态',
                    label: '挂网价状态',
                    type: 'select',
                    options: [
                        {
                            label: '等于',
                            name: 1
                        },
                        {
                            label: '大于',
                            name: 2
                        },
                        {
                            label: '小于',
                            name: 3
                        }
                    ]
                },
                挂网价: {
                    name: 'price',
                    label: '挂网价',
                    type: 'input'
                },
                产品编号: {
                    name: 'purPlatformProdCode',
                    label: '产品编号',
                    type: 'input'
                },
                材质: {
                    name: 'productMaterial',
                    label: '材质',
                    type: 'input'
                },
                配送区域: {
                    name: 'deliveryAreaId',
                    label: '配送区域',
                    type: 'select',
                    grid: 3,
                    http: {
                        url: '/aup/api/aup/delivery/area/get/deliveryarea',
                        params: {
                            projectId: this.$route.query.itemId
                        },
                        onSuccess: res => {
                            res.data.unshift({
                                areaName: '全部',
                                areaId: ''
                            });
                            return res.data;
                        }
                    },
                    props: {
                        label: 'areaName',
                        value: 'areaId'
                    }
                },
                配送区域Desc: {
                    name: 'deliveryArea',
                    label: '配送区域'
                },
                配送企业: {
                    name: 'deliveryOrg',
                    label: '配送企业',
                    type: 'input',
                    grid: 3
                },
                签约状态: {
                    name: 'contractStatus',
                    label: '签约状态',
                    type: 'select',
                    grid: 3,
                    options: [
                        {
                            label: '全部',
                            value: ''
                        },
                        {
                            label: '双方未签订',
                            value: 1
                        },
                        {
                            label: '生产企业已签，配送企业未签',
                            value: 2
                        },
                        {
                            label: '配送企业已签，生产企业未签',
                            value: 3
                        },
                        {
                            label: '双方已签订',
                            value: 4
                        },
                        {
                            label: '双方已解约',
                            value: 5
                        }
                    ]
                },
                配送协议编号: {
                    name: 'deliveryContractCode',
                    label: '配送协议编号',
                    type: 'input',
                    grid: 3
                },
                调整方案: {
                    name: 'type',
                    label: '调整方案',
                    type: 'select',
                    grid: 4,
                    options: [
                        {
                            value: null,
                            label: '全部'
                        },
                        {
                            value: 0,
                            label: '与原配送企业解约'
                        },
                        {
                            value: 2,
                            label: '调整配送产品范围'
                        },
                        {
                            value: 1,
                            label: '修改配送'
                        }
                    ]
                },
                配送点选状态: {
                    name: 'deliveryConfirmationStatus',
                    label: '配送点选状态',
                    type: 'select',
                    options: [
                        {
                            label: '全部',
                            value: ''
                        },
                        {
                            label: '待提交',
                            value: 0
                        },
                        {
                            label: '待配送确认',
                            value: 1
                        },
                        {
                            label: '配送已同意',
                            value: 2
                        },
                        {
                            label: '配送已拒绝',
                            value: 4
                        },
                        {
                            label: '方案已作废',
                            value: 5
                        }
                    ]
                },
                配送注册证数: {
                    name: 'registerCount',
                    label: '配送注册证数',
                    type: 'input'
                },
                操作人: {
                    name: 'userName',
                    label: '操作人',
                    type: 'input',
                    grid: 3
                },
                操作时间: {
                    name: 'operateDate',
                    label: '操作时间',
                    type: 'daterange',
                    grid: 3,
                    extraFields: ['operateDateStart', 'operateDateEnd'],
                    noSubmit: true
                },
                操作内容: {
                    name: 'buttonType',
                    label: '操作内容',
                    type: 'select',
                    options: [
                        {
                            label: '全部',
                            value: ''
                        },
                        {
                            label: '产品捏合',
                            value: 11
                        },
                        {
                            label: '删除产品',
                            value: 12
                        },
                        {
                            label: '调整产品',
                            value: 17
                        }
                    ]
                },
                操作对象: {
                    name: 'content',
                    label: '操作对象',
                    type: 'input'
                },
                '生产/代理企业签订时间': {
                    name: 'orgSignTime',
                    label: '生产/代理企业签订时间',
                    type: 'input',
                    grid: 3
                },
                '生产/代理企业': {
                    name: 'orgName',
                    label: '生产/代理企业',
                    type: 'input',
                    grid: 3
                },
                配送企业签订时间: {
                    name: 'deliverySignTime',
                    label: '配送企业签订时间',
                    type: 'input',
                    grid: 3
                },
                本区域配送企业: {
                    name: 'deliveryAreaOrg',
                    label: '本区域配送企业',
                    type: 'input',
                    grid: 3
                },
                配送企业名称: {
                    name: 'deliveryOrg',
                    label: '配送企业名称',
                    type: 'input',
                    grid: 3
                },
                已报名产品数: {
                    name: 'enrollProdCount',
                    label: '已报名产品数'
                },
                已报名厂家数: {
                    name: 'enrollOrgCount',
                    label: '已报名厂家数'
                },
                已报价厂家数: {
                    name: 'quotationInOrgCount',
                    label: '已报价厂家数'
                },
                报价入选产品数: {
                    name: 'quotationInProdCount',
                    label: '报价入选产品数'
                },
                报价入选厂家数: {
                    name: 'quotationInOrgCount',
                    label: '报价入选厂家数'
                },
                竞价入选产品数: {
                    name: 'competeInProdCount',
                    label: '竞价入选产品数'
                },
                竞价入选厂家数: {
                    name: 'competeInOrgCount',
                    label: '竞价入选厂家数'
                },
                谈判入选产品数: {
                    name: 'negotiationInProdCount',
                    label: '谈判入选产品数'
                },
                谈判入选厂家数: {
                    name: 'negotiationInOrgCount',
                    label: '谈判入选厂家数'
                },
                报价状态: {
                    name: 'roundQuotationStatus',
                    label: '报价状态'
                },
                首轮报价: {
                    name: 'roundQuotationStatusDesc',
                    label: '首轮报价'
                },
                解密状态: {
                    name: 'decyptFlag',
                    label: '解密状态'
                },
                报价入选状态: {
                    name: 'roundQuotationChosenStatusDesc',
                    label: '报价入选状态'
                },
                竞价入选状态: {
                    name: 'competeChosenStatusDesc',
                    label: '竞价入选状态'
                },
                谈判入选状态: {
                    name: 'negotiationChosenStatusDesc',
                    label: '谈判入选状态'
                },
                审核人: {
                    name: 'auditUser',
                    label: '审核人',
                    type: 'input'
                },
                审核时间: {
                    name: 'gmtAudit',
                    label: '审核时间',
                    type: 'daterange',
                    extraFields: ['checkTimeStart', 'checkTimeEnd'],
                    noSubmit: true
                },
                提交时间1: {
                    name: 'gmtSubmit',
                    label: '提交时间',
                    type: 'input'
                },
                商品编号: {
                    name: 'purPlatformProdCode',
                    label: '商品编号',
                    type: 'input',
                    grid: 3
                },
                类型: {
                    name: 'regType',
                    label: '类型',
                    type: 'select',
                    grid: 3,
                    options: [
                        {
                            label: '第一类医疗器械产品',
                            value: 1
                        },
                        {
                            label: '二类医疗器械产品',
                            value: 2
                        },
                        {
                            label: '三类医疗器械产品',
                            value: 5
                        },
                        {
                            label: '消毒类产品',
                            value: 3
                        },
                        {
                            label: '分类界定产品',
                            value: 4
                        }
                    ]
                },
                申报企业: {
                    name: 'enrolledOrgName',
                    label: '申报企业',
                    type: 'input',
                    grid: 3
                },
                审核意见: {
                    name: 'auditOpinion',
                    label: '审核意见',
                    type: 'textarea'
                },
                审核结果: {
                    name: 'auditStatus',
                    label: '审核结果',
                    type: 'select',
                    options: [
                        {
                            label: '审核通过',
                            value: 1
                        },
                        {
                            label: '审核不通过',
                            value: 2
                        }
                    ]
                },
                全国最低采购价证明材料: {
                    name: 'nationalMinimumPriceFile',
                    label: '全国最低采购价证明材料',
                    type: 'upload',
                    extend: {
                        name: 'file',
                        headers: {
                            from: 'Y'
                        }
                    },
                    rules: [
                        {
                            required: true,
                            message: '请上传',
                            trigger: 'blur'
                        }
                    ],
                    grid: 1
                },
                全国最低采购价: {
                    name: 'nationalMinimumPrice',
                    label: '全国最低采购价',
                    type: 'input'
                },
                报名状态: {
                    name: 'enrolledFlagDesc',
                    label: '报名状态'
                },
                分类名称: {
                    name: 'catalogParentNum',
                    label: '分类名称',
                    type: 'select',
                    http: {
                        url: catalogClass,
                        method: 'get',
                        onSuccess: res => [
                            {
                                catalogName: '全部',
                                catalogNum: ''
                            },
                            ...res.data
                        ]
                    },
                    props: {
                        value: 'catalogNum',
                        label: 'catalogName'
                    },
                    grid: 3
                },
                换算比: {
                    label: '换算比',
                    name: 'conversionRatio'
                },
                注册证状态: {
                    label: '注册证状态',
                    name: 'regCardStatus',
                    type: 'select',
                    options: [
                        {

                            label: '全部',
                            value: ''
                        },
                        {
                            label: '正常',
                            value: 0
                        },
                        {
                            label: '过期',
                            value: 1
                        },
                        {
                            label: '近效期',
                            value: 2
                        }
                    ]
                },
                管理类别: {
                    label: '管理类别',
                    name: 'managementType',
                    type: 'select',
                    options: [
                        {
                            label: '全部',
                            value: ''
                        },
                        {
                            label: '一类',
                            value: 1
                        },
                        {
                            label: '二类',
                            value: 2
                        },
                        {
                            label: '三类',
                            value: 3
                        }
                    ]
                },
                注册证有效期起: {
                    name: 'validityStart',
                    type: 'daterange',
                    label: '注册证有效期起',
                    grid: 3,
                    extraFields: ['beginValidityStart', 'endValidityStart'],
                    noSubmit: true
                },
                注册证有效期止: {
                    name: 'validityEnd',
                    type: 'daterange',
                    label: '注册证有效期止',
                    grid: 3,
                    extraFields: ['beginValidityEnd', 'endValidityEnd'],
                    noSubmit: true
                },
                单一规格产品编码: {
                    label: '单一规格产品编码',
                    name: 'singleProductCode',
                    type: 'input',
                    grid: 3
                },
                生产企业签订时间: {
                    label: '生产企业签订时间',
                    name: 'orgSignTime'
                },
                解约时间: {
                    label: '解约时间',
                    name: 'cancelTime'
                },
                产品材质: {
                    label: '产品材质',
                    name: 'productMaterial'
                },
                操作注册证数: {
                    label: '操作注册证数',
                    name: 'operationCount'
                },
                确认状态: {
                    label: '确认状态',
                    name: 'deliveryConfirmationStatus',
                    type: 'select',
                    options: [
                        {
                            label: '全部',
                            value: ''
                        },
                        {
                            label: '待确认',
                            value: 1
                        },
                        {
                            label: '已确认',
                            value: 2
                        },
                        {
                            label: '已拒绝',
                            value: 3
                        }
                    ]
                },
                分类编号: {
                    name: 'pcatalogNum',
                    label: '分类编号',
                    type: 'input',
                    grid: 3
                },
                目录描述: {
                    label: '目录描述',
                    name: 'catalogDescribe',
                    type: 'textarea'
                },
                是否组套目录: {
                    label: '是否组套目录',
                    name: 'packageFlag',
                    type: 'radio',
                    required: true,
                    options: [
                        {
                            label: '是',
                            value: 1
                        },
                        {
                            label: '否',
                            value: 0
                        }
                    ]
                },
                '上一年度采购总量（最小计量单位）': {
                    label: '上一年度采购总量（最小计量单位）',
                    name: 'lastYearMinPackProcureQty'
                },
                '上一年度采购总金额（最小计量单位）': {
                    label: '上一年度采购总金额（最小计量单位）',
                    name: 'lastYearMinUnitProcureAmt'
                },
                '价格区间（最小计量单位）': {
                    label: '价格区间（最小计量单位）',
                    name: 'priceRange'
                },
                最低价格医院: {
                    label: '最低价格医院',
                    name: 'minPriceHosp'
                },
                最高价格医院: {
                    label: '最高价格医院',
                    name: 'maxPriceHosp'
                },
                '采购单价（元/最小计量单位）': {
                    label: '采购单价（元/最小计量单位）',
                    name: 'minUnitProcurePrice'
                },
                '采购单价（元/最小包装单位）': {
                    label: '采购单价（元/最小包装单位）',
                    name: 'minPackProcurePrice'
                },
                '上一年度采购数量（最小计量单位）': {
                    label: '上一年度采购数量（最小计量单位）',
                    name: 'lastYearMinPackProcureQty'
                },
                '上一年度采购总金额（元/最小计量单位）': {
                    label: '上一年度采购总金额（元/最小计量单位）',
                    name: 'lastYearMinUnitProcureAmt'
                },
                医疗机构类型: {
                    name: 'hospType',
                    label: '医疗机构类型',
                    type: 'select',
                    options: [
                        {
                            label: '全部',
                            value: ''
                        },
                        {
                            label: '公立医疗机构',
                            value: 0
                        },
                        {
                            label: '军队医疗机构',
                            value: 1
                        },
                        {
                            label: '医保定点非公立医疗机构',
                            value: 2
                        }
                    ]
                },
                医疗机构等级: {
                    name: 'grade',
                    label: '医疗机构等级',
                    type: 'select',
                    options: [],
                    http: expertMenu('hosp_sublevel'),
                    ...expertProps()
                },
                '开放床位数（张）': {
                    name: 'bedQty',
                    label: '开放床位数（张）',
                    type: 'input'
                },
                '上年度诊疗人次数（人）': {
                    name: 'treatPersonQty',
                    label: '上年度诊疗人次数（人）',
                    type: 'input'
                },
                发票下载: {
                    name: 'invoiceFile',
                    label: '发票下载'
                },
                发票预览: {
                    name: 'invoiceFile',
                    label: '发票预览',
                    type: 'image'
                },
                随货同行下载: {
                    name: 'cargoInvoiceFile',
                    label: '随货同行下载',
                    formExtend: {
                        type: 'upload',
                        readonly: true
                    }
                },
                随货同行预览: {
                    name: 'cargoInvoiceFile',
                    label: '随货同行预览',
                    type: 'image'
                },
                填报人: {
                    name: 'fillUserName',
                    label: '填报人',
                    type: 'input'
                },
                联系电话: {
                    name: 'phone',
                    label: '联系电话',
                    type: 'input'
                },
                操作账号: {
                    name: 'account',
                    label: '操作账号'
                },
                操作类型: {
                    name: 'optTypeDesc',
                    label: '操作类型'
                },
                专家编码: {
                    label: '专家编码',
                    name: 'expertCode'
                },
                专家姓名: {
                    label: '专家姓名',
                    name: 'expertName',
                    type: 'input'
                },
                性别: {
                    label: '性别',
                    name: 'sex',
                    type: 'select',
                    options: [],
                    http: expertMenu('sex'),
                    ...expertProps()
                },
                所属机构: {
                    label: '所属机构',
                    name: '所属机构',
                    type: 'input'
                },
                所属地区: {
                    label: '所属地区',
                    name: '所属地区'
                },
                专家类型: {
                    label: '专家类型',
                    name: 'expertType',
                    type: 'select',
                    options: [],
                    http: expertMenu('hc_expert_type'),
                    ...expertProps()
                },
                专业方向: {
                    label: '专业方向',
                    name: 'majorDirection',
                    type: 'select',
                    options: [],
                    http: expertMenu('major_direction'),
                    ...expertProps()
                },
                专家职称: {
                    label: '专家职称',
                    name: 'expertTitle',
                    type: 'select',
                    options: [],
                    http: expertMenu('expert_title'),
                    ...expertProps()
                },
                专家科室: {
                    label: '专家科室',
                    name: 'department',
                    type: 'cascader',
                    http: {
                        url: '/aup/api/aup/base/dictionary/getDepartmentLabelTree',
                        onSuccess: res => {
                            res.data.unshift({
                                labelName: '全部',
                                labelCode: ''
                            });
                            return res.data;
                        }
                    },
                    ...expertProps(),
                    separator: '-',
                    loadModel: 'onceAll'
                },
                联系方式: {
                    label: '联系方式',
                    name: 'phone',
                    type: 'input'
                },
                专家选择状态: {
                    label: '状态',
                    name: 'chooseFlagDesc'
                },
                显示记录: {
                    name: 'fillFlag',
                    label: '显示记录',
                    type: 'select',
                    grid: 2,
                    options: [
                        {
                            label: '全部',
                            value: ''
                        },
                        {
                            label: '只显示未填写记录',
                            value: 1
                        },
                        {
                            label: '只显示已填写记录',
                            value: 2
                        },
                        {
                            label: '只显示部分未填写记录',
                            value: 3
                        }
                    ]
                },
                服务分类: {
                    name: 'queType',
                    label: '服务分类',
                    type: 'select',
                    options: [
                        {
                            label: '全部',
                            value: ''
                        },
                        {
                            label: '申诉',
                            value: 1
                        },
                        {
                            label: '投诉',
                            value: 2
                        },
                        {
                            label: '动态调整申请',
                            value: 3
                        }
                    ]
                },
                服务事项: {
                    name: 'queItemId',
                    label: '服务事项',
                    type: 'select',
                    http: {
                        url: '/aup/api/aup/que/item/list',
                        params: {
                            queType: '',
                            itemStatus: 1
                        }
                    },
                    props: {
                        label: 'itemName',
                        value: 'id'
                    },
                    depend: {
                        name: 'queType',
                        onChange: (val, that) => {
                            that.materialConfig.http.params.queType = val;
                        }
                    }
                },
                服务状态: {
                    name: 'itemStatus',
                    label: '状态',
                    type: 'select',
                    options: [
                        {
                            label: '全部',
                            value: ''
                        },
                        {
                            label: '已启用',
                            value: 1
                        },
                        {
                            label: '已禁用',
                            value: 2
                        }
                    ]
                },
                证明材料是否必传: {
                    name: 'certificateFlag',
                    label: '证明材料是否必传',
                    type: 'radio',
                    required: true,
                    options: [
                        {
                            label: '是',
                            value: 1
                        },
                        {
                            label: '否',
                            value: 0
                        }
                    ]
                },
                备注: {
                    name: 'remark',
                    label: '备注',
                    type: 'textarea'
                },
                申投诉单号: {
                    name: 'queTaskNum',
                    label: '申投诉单号',
                    type: 'input'
                },
                申投诉单号1: {
                    name: 'queTaskNum',
                    label: '申投诉单号'
                },
                标题: {
                    label: '标题',
                    name: 'queTitle',
                    type: 'input',
                    extend: {
                        maxlength: 50
                    }
                },
                处理状态: {
                    label: '处理状态',
                    name: 'auditStatus',
                    type: 'select',
                    options: [
                        {
                            label: '全部',
                            value: ''
                        },
                        {
                            label: '待审核',
                            value: 2
                        },
                        {
                            label: '审核通过',
                            value: 3
                        },
                        {
                            label: '审核不通过',
                            value: 4
                        },
                        {
                            label: '已撤回',
                            value: 5
                        }
                    ]
                },
                处理人: {
                    label: '处理人',
                    name: 'auditUserName',
                    type: 'input'
                },
                处理时间: {
                    label: '处理时间',
                    name: 'auditTime'
                },
                账号: {
                    label: '账号',
                    name: 'username',
                    type: 'input'
                },
                姓名: {
                    label: '姓名',
                    name: 'nickName',
                    type: 'input'
                },
                账户类型: {
                    label: '账户类型',
                    name: 'roleNames'
                },
                分发数量: {
                    label: '分发数量',
                    name: '分发数量'
                },
                申诉确认状态: {
                    label: '确认状态',
                    name: 'confirmStatus',
                    type: 'select',
                    options: [
                        {
                            label: '全部',
                            value: ''
                        },
                        {
                            label: '待确认',
                            value: 1
                        },
                        {
                            label: '审核通过',
                            value: 2
                        },
                        {
                            label: '审核不通过',
                            value: 3
                        }
                    ]
                },
                受理意见: {
                    label: '受理意见',
                    name: 'acceptanceOpinion',
                    type: 'textarea'
                },
                确认意见: {
                    label: '确认意见',
                    name: 'confirmResult',
                    type: 'textarea'
                },
                回复内容: {
                    label: '回复内容',
                    name: 'auditResult',
                    type: 'textarea'
                },
                联系人: {
                    label: '联系人',
                    name: 'queContacts',
                    type: 'input',
                    extend: {
                        maxlength: 20
                    }
                },
                被投诉企业: {
                    name: 'queOrgName',
                    label: '被投诉企业',
                    type: 'tag'
                },
                证明材料: {
                    name: 'credential',
                    label: '证明材料',
                    type: 'upload',
                    extend: {
                        name: 'file',
                        headers: {
                            from: 'Y'
                        },
                        limit: 20,
                        accept: '.pdf'
                    }
                },
                涉及产品: {
                    name: 'queProductList',
                    label: '涉及产品',
                    type: 'tag'
                },
                产品ID: {
                    name: 'purPlatformProdCode',
                    label: '产品ID',
                    grid: 2
                },
                审核结果确认: {
                    name: 'confirmStatus',
                    label: '审核结果',
                    type: 'select',
                    options: [
                        {
                            label: '审核通过',
                            value: 2
                        },
                        {
                            label: '审核不通过',
                            value: 3
                        }
                    ]
                },
                项目名称: {
                    name: 'projectName',
                    label: '项目名称',
                    type: 'input'
                },
                包装: {
                    name: 'productPackMaterial',
                    label: '包装',
                    type: 'input'
                },
                专家所属地区: {
                    name: 'completeAddressDesc',
                    type: 'cascader',
                    label: '所属地区',
                    http: {
                        url: '/aup/api/aup/base/area/listArea',
                        method: 'get',
                        params: {
                            default: 'parentId',
                            defaultValue: ''
                        }
                    },
                    separator: '-',
                    level: 3,
                    grid: 1,
                    props: {
                        label: 'name',
                        value: 'areaId'
                    },
                    extraFields: ['provinceId', 'cityId', 'countyId']
                },
                单位名称: {
                    name: 'workOrgName',
                    label: '单位名称',
                    type: 'input'
                },
                单位类别: {
                    name: 'workOrgType',
                    label: '单位类别',
                    type: 'select',
                    options: [],
                    http: expertMenu('work_org_type'),
                    ...expertProps()
                },
                医疗机构级别: {
                    name: 'hospLevel',
                    label: '医疗机构级别',
                    type: 'select',
                    options: [],
                    http: expertMenu('hosp_level'),
                    ...expertProps()
                },
                使用类别: {
                    name: 'useKind',
                    label: '使用类别',
                    type: 'select',
                    options: [],
                    http: expertMenu('use_kind'),
                    ...expertProps()
                },
                职称等级: {
                    name: 'titleLevel',
                    label: '职称等级',
                    type: 'select',
                    options: [],
                    http: expertMenu('title_level'),
                    ...expertProps()
                },
                行政职务: {
                    name: 'administrativeDuty',
                    label: '行政职务',
                    type: 'select',
                    options: [],
                    http: expertMenu('administrative_duty'),
                    ...expertProps()
                },
                是否为人大或政协委员: {
                    name: 'committeeMemberFlag',
                    label: '是否为人大或政协委员',
                    type: 'select',
                    options: [
                        {
                            label: '全部',
                            value: ''
                        },
                        {
                            label: '是',
                            value: 1
                        },
                        {
                            label: '否',
                            value: 0
                        }
                    ]
                },
                执业年限: {
                    name: 'practiceYears',
                    label: '执业年限',
                    type: 'input'
                },
                专家状态: {
                    name: '状态',
                    label: '状态',
                    type: 'select',
                    options: [
                        {
                            label: '全部',
                            value: ''
                        },
                        {
                            label: '启用',
                            value: 1
                        },
                        {
                            label: '禁用',
                            value: 2
                        }
                    ]
                },
                出生日期: {
                    name: 'birthdate',
                    label: '出生日期',
                    type: 'date'
                },
                执业证书编码: {
                    name: 'certificateCode',
                    label: '执业证书编码',
                    type: 'input'
                },
                学历: {
                    name: 'education',
                    label: '学历',
                    type: 'select',
                    options: [],
                    http: expertMenu('education'),
                    ...expertProps()
                },
                医保定点: {
                    name: 'healthInsuranceFlag',
                    label: '医保定点',
                    type: 'radio',
                    options: [
                        {
                            label: '是',
                            value: true
                        },
                        {
                            label: '否',
                            value: false
                        }
                    ]
                },
                内容: {
                    name: 'queContent',
                    label: '内容',
                    type: 'textarea'
                },
                数据范围: {
                    name: 'dataType',
                    label: '数据范围',
                    type: 'select',
                    http: {
                        url: enumsUrl,
                        params: {
                            enumsName: 'QueDataTypeEnum'
                        }
                    },
                    props: {
                        label: 'desc'
                    }
                },
                '服务事项-form': {
                    name: 'itemName',
                    label: '服务事项',
                    type: 'input'
                },
                是否配置目录: {
                    name: 'relaClassifyFlag',
                    label: '是否配置目录',
                    type: 'select',
                    options: [
                        {
                            label: '全部',
                            value: ''
                        },
                        {
                            label: '是',
                            value: 1
                        },
                        {
                            label: '否',
                            value: 0
                        }
                    ]
                },
                是否绑定账号: {
                    name: 'relaUserFlag',
                    label: '是否绑定账号',
                    type: 'select',
                    options: [
                        {
                            label: '全部',
                            value: ''
                        },
                        {
                            label: '是',
                            value: 1
                        },
                        {
                            label: '否',
                            value: 0
                        }
                    ]
                },
                关联账号: {
                    name: '关联账号',
                    label: '关联账号'
                },
                机构类型: {
                    name: 'orgName',
                    label: '机构类型'
                },
                账号角色: {
                    name: 'roleName',
                    label: '账号角色',
                    type: 'input'
                },
                任务名称: {
                    name: 'taskName',
                    label: '任务名称',
                    type: 'input'
                },
                发起机构: {
                    name: 'sponsorOrgName',
                    label: '发起机构',
                    type: 'input'
                },
                抽取方式: {
                    name: 'extractMode',
                    label: '抽取方式',
                    type: 'select',
                    options: [],
                    http: expertMenu('extract_mode'),
                    ...expertProps()
                },
                任务分类: {
                    name: '任务分类',
                    label: '任务分类',
                    type: 'select',
                    options: [
                        {
                            label: '全部',
                            value: ''
                        },
                        {
                            label: '药品',
                            value: 1
                        },
                        {
                            label: '耗材',
                            value: 2
                        }
                    ]
                },
                专家范围配置: {
                    name: 'scopeFlag',
                    label: '专家范围配置',
                    type: 'select',
                    options: [],
                    http: expertMenu('scope_flag'),
                    ...expertProps()
                },
                任务编号: {
                    name: 'taskCode',
                    label: '任务编号'
                },
                项目负责人联系电话: {
                    name: 'linkInfo',
                    label: '项目负责人联系电话'
                },
                /** 直接挂网字段--start */
                挂网状态: {
                    name: 'directPubStatus',
                    label: '挂网状态',
                    type: 'select',
                    options: [
                        {
                            label: '全部',
                            value: ''
                        },
                        {
                            label: '已挂网',
                            value: 1
                        },
                        {
                            label: '未挂网',
                            value: 0
                        },
                        {
                            label: '未处理',
                            value: 3
                        }
                    ]
                },
                直接挂网报价状态: {
                    name: 'quationStatus',
                    label: '报价状态',
                    type: 'select',
                    options: [
                        {
                            label: '全部',
                            value: ''
                        },
                        {
                            label: '已报价',
                            value: 1
                        },
                        {
                            label: '未报价',
                            value: 0
                        }
                    ]
                },
                生效状态: {
                    name: 'transSyncStatus',
                    label: '生效状态',
                    type: 'select',
                    options: [
                        {
                            label: '全部',
                            value: ''
                        },
                        {
                            label: '已生效',
                            value: 1
                        },
                        {
                            label: '未生效',
                            value: 0
                        }
                    ]
                },
                企业报价: {
                    label: '企业报价（最小使用单位）',
                    name: 'quationPrice',
                    type: 'input'
                },
                降幅: {
                    label: '降幅',
                    name: 'decline'
                },
                原因: {
                    label: '原因',
                    name: 'directPubReason',
                    type: 'textarea',
                    required: true,
                    extend: {
                        maxlength: 100
                    }
                },
                限价: {
                    label: '限价',
                    name: 'limitedPrice'
                },
                /** 直接挂网字段--end */
                招标模式: {
                    label: '招标模式',
                    name: 'projectType',
                    type: 'select',
                    options: [
                        {
                            label: '谈判/竞价模式',
                            value: 1
                        },
                        {
                            label: '谈判模式',
                            value: 2
                        },
                        {
                            label: '直接挂网模式',
                            value: 3
                        }
                    ]
                },
                专家组名称: {
                    name: 'groupName',
                    label: '专家组名称',
                    type: 'input'
                },
                专家组成员: {
                    name: 'relaExpertName',
                    label: '专家组成员'
                },
                账号名称: {
                    name: 'upmsUserAccout',
                    label: '账号名称'
                },
                分类: {
                    name: 'relaClassifyName',
                    label: '分类'
                },
                是否关联账号: {
                    name: 'relaUserFlag',
                    label: '是否关联账号',
                    type: 'select',
                    options: [
                        {
                            label: '全部',
                            value: ''
                        },
                        {
                            label: '是',
                            value: 1
                        },
                        {
                            label: '否',
                            value: 0
                        }
                    ]
                },
                是否关联专家: {
                    name: 'relaExpertFlag',
                    label: '是否关联专家',
                    type: 'select',
                    options: [
                        {
                            label: '全部',
                            value: ''
                        },
                        {
                            label: '是',
                            value: 1
                        },
                        {
                            label: '否',
                            value: 0
                        }
                    ]
                },
                所属项目Search: {
                    name: 'projectName',
                    label: '所属项目',
                    type: 'input'
                },
                通用名: {
                    name: '通用名',
                    label: '通用名'
                },
                包装材质: {
                    name: 'productPackMaterial',
                    label: '包装材质'
                },
                '品牌/商标': {
                    name: '品牌/商标',
                    label: '品牌/商标'
                },
                产地: {
                    name: '产地',
                    label: '产地'
                },
                产品有效期: {
                    name: '产品有效期',
                    label: '产品有效期'
                },
                基本性能描述: {
                    name: '基本性能描述',
                    label: '基本性能描述'
                },
                保质年限出厂标准: {
                    name: '保质年限出厂标准',
                    label: '保质年限出厂标准'
                },
                '基本功能描述：心室脉冲振幅（v）': {
                    name: '基本功能描述：心室脉冲振幅（v）',
                    label: '基本功能描述：心室脉冲振幅（v）'
                },
                '基本功能描述：可转换工作模式': {
                    name: '基本功能描述：可转换工作模式',
                    label: '基本功能描述：可转换工作模式'
                },
                性能结构与组成: {
                    name: 'makeUp',
                    label: '性能结构与组成'
                },
                适用范围: {
                    name: 'scope',
                    label: '适用范围'
                },
                产品使用方法: {
                    name: 'productUsage',
                    label: '产品使用方法'
                },
                最小包装规格: {
                    name: '最小包装规格',
                    label: '最小包装规格'
                },
                调整类型: {
                    name: 'type',
                    label: '调整类型',
                    type: 'checkbox',
                    grid: 1,
                    options: [
                        {
                            value: 1,
                            label: '挂网状态'
                        },
                        {
                            value: 2,
                            label: '挂网价格'
                        },
                        {
                            value: 3,
                            label: '目录'
                        }
                    ]
                },
                调整类型1: {
                    name: 'typeDesc',
                    label: '调整类型',
                    grid: 1
                },
                调整前挂网状态: {
                    name: 'statusBeforeDesc',
                    label: '调整前挂网状态',
                    grid: 2
                },
                调整后挂网状态: {
                    name: 'statusAfter',
                    label: '调整后挂网状态',
                    type: 'radio',
                    disabled: true,
                    grid: 2,
                    options: [
                        {
                            value: 1,
                            label: '申请挂网'
                        },
                        {
                            value: 0,
                            label: '申请取消挂网'
                        }
                    ]
                },
                调整后挂网状态1: {
                    name: 'statusAfterDesc',
                    label: '调整后挂网状态',
                    grid: 2
                },

                调整前挂网价格: {
                    name: 'priceBefore',
                    label: '调整前挂网价格',
                    grid: 2
                },
                调整后挂网价格: {
                    name: 'priceAfter',
                    label: '调整后挂网价格',
                    type: 'input',
                    // required: true,
                    readonly: true,
                    textAfter: '元/盒',
                    grid: 2,
                    rules: [
                        {
                            required: false,
                            message: '请输入',
                            trigger: 'blur'
                        }
                    ]
                },
                调整后挂网价格1: {
                    name: 'priceAfter',
                    label: '调整后挂网价格',
                    grid: 2
                },
                调整前所属项目: {
                    name: 'projectNameBefore',
                    label: '调整前所属项目',
                    grid: 2
                },
                调整后所属项目: {
                    name: 'projectNameAfter',
                    label: '调整后所属项目',
                    grid: 2
                },
                调整前目录名称: {
                    name: 'catalogNameBefore',
                    label: '调整前目录名称',
                    grid: 2
                },
                调整后目录名称: {
                    name: 'catalogNameAfter',
                    label: '调整后目录名称',
                    disabled: true,
                    type: 'select',
                    http: {
                        url: '/aup/api/aup/procureCatalog/page/tenderCatalog/chosed'
                    },
                    props: {
                        label: 'catalogName',
                        value: 'catalogNum'
                    },
                    grid: 2
                },
                调整后目录名称1: {
                    name: 'catalogNameAfter',
                    label: '调整后目录名称',
                    grid: 2
                },
                调整后目录: {
                    name: 'catalogNameAfter',
                    label: '调整后目录'
                },
                调整原因: {
                    name: 'reason',
                    label: '调整原因',
                    grid: 1
                },
                调整依据: {
                    name: 'queTaskNum',
                    label: '调整依据'
                },
                协议编号: {
                    name: 'deliveryContractCode',
                    label: '协议编号',
                    type: 'input'
                },
                动态调整状态: {
                    name: '动态调整状态',
                    label: '动态调整状态',
                    type: 'input'
                },
                当前有效配送产品数: {
                    name: '当前有效配送产品数',
                    label: '当前有效配送产品数'
                },
                调整类型说明: {
                    name: 'typExplain',
                    label: '调整类型说明',
                    noSubmit: true
                },
                调整前产品数量: {
                    name: 'prodQtyBefore',
                    label: '调整前产品数量',
                    grid: 2
                },
                调整前产品数: {
                    name: 'prodQtyBefore',
                    label: '调整前产品数'
                },
                调整后产品数: {
                    name: 'prodQty',
                    label: '调整后产品数'
                },
                调整前配送企业: {
                    name: 'deliveryNameBefore',
                    label: '调整前配送企业',
                    grid: 2
                },
                调整后配送企业: {
                    name: 'deliveryIdAfter',
                    label: '调整后配送企业',
                    required: false,
                    type: 'select',
                    rules: [
                        {
                            required: false,
                            message: '请选择',
                            trigger: 'blur'
                        }
                    ],
                    http: {
                        url: '/aup/api/aup/delivery/plan/pageDeliveryOrg',
                        onSuccess: res => res.data.records
                    },
                    props: {
                        label: 'deliveryOrg',
                        value: 'deliveryId'
                    },
                    grid: 2
                },
                生效时间1: {
                    name: 'sendTime',
                    label: '生效时间'
                },
                编号: {
                    name: 'singleNo',
                    label: '编号'
                },
                配送产品数: {
                    name: 'prodQty',
                    label: '配送产品数'
                },
                本次调整产品数量: {
                    name: '本次调整产品数量',
                    label: '本次调整产品数量',
                    grid: 2,
                    button: {
                        name: 'productDetail',
                        label: '产品明细',
                        callback: () => { }
                    }
                },
                挂网价格: {
                    name: 'price',
                    label: '挂网价格(元)'
                },
                发起调整时间1: {
                    name: 'gmtCreated',
                    label: '发起调整时间'
                },
                调整内容1: {
                    name: 'type',
                    label: '调整内容',
                    type: 'select',
                    options: [
                        {
                            label: '挂网状态',
                            value: 0
                        },
                        {
                            label: '挂网价格',
                            value: 1
                        },
                        {
                            label: '目录',
                            value: 2
                        },
                        {
                            label: '挂网价格及状态',
                            value: 3
                        },
                        {
                            label: '挂网价格及目录',
                            value: 4
                        },
                        {
                            label: '挂网状态及目录',
                            value: 5
                        },
                        {
                            label: '挂网状态、价格及目录',
                            value: 6
                        }
                    ]
                },
                本次调整产品数: {
                    name: '本次调整产品数',
                    label: '本次调整产品数'
                },
                原配送企业: {
                    name: '原配送企业',
                    label: '原配送企业'
                },
                调整内容: {
                    name: 'adjustType',
                    label: '调整内容',
                    type: 'select',
                    options: [
                        {
                            label: '全部',
                            value: ''
                        },
                        {
                            label: '挂网价格',
                            value: '1'
                        },
                        {
                            label: '挂网状态',
                            value: '2'
                        },
                        {
                            label: '挂网价格及状态',
                            value: '3'
                        }
                    ]
                },
                调整状态: {
                    name: 'adjustStas',
                    label: '调整状态',
                    type: 'select',
                    options: [
                        {
                            label: '全部',
                            value: ''
                        },
                        {
                            label: '待企业确认',
                            value: '1'
                        },
                        {
                            label: '管理机构已撤回',
                            value: '2'
                        },
                        {
                            label: '企业已确认-不同意调整',
                            value: '3'
                        },
                        {
                            label: '企业已确认-同意调整',
                            value: '4'
                        },
                        {
                            label: '管理机构直接调整',
                            value: '5'
                        },
                        {
                            label: '待管理机构审核',
                            value: '6'
                        },
                        {
                            label: '管理机构已审核-同意调整',
                            value: '7'
                        },
                        {
                            label: '管理机构已审核-不同意调整',
                            value: '8'
                        },
                        {
                            label: '企业已撤回',
                            value: '9'
                        }
                    ]
                },
                '挂网价格（元）': {
                    name: 'price',
                    label: '挂网价格（元）'
                },
                发起调整时间: {
                    name: 'adjustTime',
                    label: '发起调整时间'
                },
                生效时间: {
                    name: 'effectTime',
                    label: '生效时间'
                },
                配送调整状态: {
                    name: '配送调整状态',
                    label: '配送调整状态'
                },
                处理意见: {
                    name: 'confirmOpinion',
                    label: '处理意见'
                },
                处理意见附件: {
                    name: 'confirmFileId',
                    label: '处理意见附件',
                    type: 'upload',
                    extend: {
                        headers: {
                            from: 'Y'
                        },
                        name: 'file'
                    }
                },
                调整依据1: {
                    name: 'fileId',
                    label: '调整依据'
                },
                处理结果: {
                    name: '处理结果',
                    label: '处理结果'
                },
                附件: {
                    name: '附件',
                    label: '附件'
                },
                // 调整前配送产品数: {
                //     name: '调整前配送产品数',
                //     label: '调整前配送产品数'
                // },
                // 调整后配送产品数: {
                //     name: '调整后配送产品数',
                //     label: '调整后配送产品数'
                // },
                // 调整前配送企业: {
                //     name: '调整前配送企业',
                //     label: '调整前配送企业'
                // },
                // 调整后配送企业: {
                //     name: '调整后配送企业',
                //     label: '调整后配送企业'
                // },
                调整前配送产品数: {
                    name: '调整前配送产品数',
                    label: '调整前配送产品数'
                },
                调整后配送产品数: {
                    name: '调整后配送产品数',
                    label: '调整后配送产品数'
                },
                调整方案说明: {
                    name: 'typExplain',
                    label: '调整类型说明',
                    noSubmit: true
                },
                申请调整时间: {
                    name: 'gmtCreated',
                    label: '申请调整时间'
                },
                '生产企业/代理企业': {
                    name: '生产企业/代理企业',
                    label: '生产企业/代理企业'
                },
                企业发起调整时间: {
                    name: 'gmtCreated',
                    label: '企业发起调整时间'
                },
                /** 配送 */
                模板名称: {
                    label: '模板名称',
                    name: 'templateName',
                    type: 'input',
                    extend: {
                        maxlength: 20
                    }
                },
                模板类型: {
                    label: '模板类型',
                    name: 'templateType',
                    type: 'select',
                    options: [
                        {
                            label: '全部',
                            value: ''
                        },
                        {
                            label: '委托配送协议',
                            value: 1
                        },
                        {
                            label: '三方购销协议',
                            value: 2
                        },
                        {
                            label: '两方购销协议',
                            value: 3
                        }
                    ]
                },
                适用对象: {
                    label: '适用对象',
                    name: 'applicableObjects',
                    type: 'select',
                    readonly: true,
                    multiple: true,
                    depend: {
                        name: 'templateType',
                        clearSwitch: false,
                        onChange: (val, that) => {
                            that.formParams.applicableObjects = val;
                        }
                    },
                    separator: '、',
                    options: [
                        {
                            label: '生产/代理企业、配送企业',
                            value: 1
                        },
                        {
                            label: '生产/代理企业、配送企业、医疗机构',
                            value: 2
                        },
                        {
                            label: '配送企业、医疗机构',
                            value: 3
                        }
                    ]
                },
                启动时间: {
                    label: '启动时间',
                    name: 'startTime'
                },
                模板状态: {
                    label: '状态',
                    name: 'state',
                    type: 'select',
                    options: [
                        {
                            label: '全部',
                            value: ''
                        },
                        {
                            label: '未启动',
                            value: 1
                        },
                        {
                            label: '已启动',
                            value: 2
                        },
                        {
                            label: '已使用',
                            value: 3
                        }
                    ]
                },
                模板附件: {
                    label: '附件',
                    name: 'file',
                    type: 'upload',
                    extend: {
                        name: 'file',
                        headers: {
                            from: 'Y'
                        },
                        accept: '.pdf'
                    }
                },
                内容备注: {
                    label: '内容备注',
                    name: 'remarks',
                    type: 'textarea',
                    extend: {
                        maxlength: 100
                    }
                },
                是否开启: {
                    label: '是否开启',
                    name: 'state',
                    type: 'radio',
                    options: [
                        {
                            label: '是',
                            value: 2
                        },
                        {
                            label: '否',
                            value: 1
                        }
                    ]
                },
                选择状态: {
                    label: '选择状态',
                    name: '选择状态'
                },
                合同编号: {
                    label: '合同编号',
                    name: 'deliveryContractCode',
                    type: 'input'
                },
                生产企业签订状态: {
                    label: '生产企业签订状态',
                    name: 'orgSign',
                    type: 'select',
                    options: [
                        {
                            label: '全部',
                            value: ''
                        },
                        {
                            label: '未签定',
                            value: 0
                        },
                        {
                            label: '已签订',
                            value: 1
                        },
                        {
                            label: '已作废',
                            value: 2
                        }
                    ]
                },
                配送企业签订状态: {
                    label: '配送企业签订状态',
                    name: 'deliverySign',
                    type: 'select',
                    options: [
                        {
                            label: '全部',
                            value: ''
                        },
                        {
                            label: '未签定',
                            value: 0
                        },
                        {
                            label: '已签订',
                            value: 1
                        },
                        {
                            label: '已作废',
                            value: 2
                        }
                    ]
                },
                医疗机构签订状态: {
                    label: '医疗机构签订状态',
                    name: 'hospSign',
                    type: 'select',
                    options: [
                        {
                            label: '全部',
                            value: ''
                        },
                        {
                            label: '未签定',
                            value: 0
                        },
                        {
                            label: '已签订',
                            value: 1
                        },
                        {
                            label: '已作废',
                            value: 2
                        }
                    ]
                },
                医疗机构签订时间: {
                    name: '医疗机构签订时间',
                    label: '医疗机构签订时间',
                    type: 'input',
                    grid: 3
                },
                采购平台产品ID: {
                    name: 'purPlatFormProId',
                    label: '采购平台产品ID',
                    type: 'input'
                },
                已选配送企业: {
                    name: '已选配送企业',
                    label: '已选配送企业'
                },
                配送企业是否签订: {
                    name: '配送企业是否签订',
                    label: '配送企业是否签订'
                },
                医疗机构是否签订: {
                    name: '医疗机构是否签订',
                    label: '医疗机构是否签订'
                },
                合同签订状态: {
                    label: '合同签订状态',
                    name: 'contractStatus',
                    type: 'select',
                    options: [
                        {
                            label: '全部',
                            value: ''
                        },
                        {
                            label: '双方未签订',
                            value: 1
                        },
                        {
                            label: '生产企业已签订，配送机构未签订',
                            value: 3
                        },
                        {
                            label: '双方已签订',
                            value: 5
                        },
                        {
                            label: '双方未签订（直配）',
                            value: 2
                        },
                        {
                            label: '双方已签订（直配）',
                            value: 6
                        },
                        {
                            label: '已作废',
                            value: 10
                        }
                    ]
                },
                /** 配送 */
                /** 产品捏合-1.4.4 */
                操作前: {
                    name: 'operateBefore',
                    label: '操作前'
                },
                操作后: {
                    name: 'operateAfter',
                    label: '操作后'
                },
                已捏合: {
                    name: 'kneadingCount',
                    label: '已捏合'
                },
                未捏合: {
                    name: 'unKneadingCount',
                    label: '未捏合'
                },
                /** 产品捏合-1.4.4 */


                关联状态: {
                    name: 'relationFlag',
                    label: '关联状态',
                    type: 'select',
                    options: [
                        {
                            label: '全部',
                            value: ''
                        },
                        {
                            label: '未关联',
                            value: false
                        },
                        {
                            label: '已关联',
                            value: true
                        }
                    ]
                },
                产品名称中文: {
                    name: 'goodsnameYj',
                    label: '产品名称中文',
                    type: 'input'
                },
                注册证号: {
                    name: 'regcode',
                    label: '注册证号',
                    type: 'input'
                },
                产品规格: {
                    name: 'outlookc',
                    label: '产品规格',
                    type: 'input'
                },
                产品型号: {
                    name: 'goodstype',
                    label: '产品型号',
                    type: 'input'
                },
                品牌: {
                    name: 'brand',
                    label: '品牌',
                    type: 'input'
                },
                商品名: {
                    name: 'goodsname',
                    label: '商品名',
                    type: 'input'
                },
                组套目录: {
                    name: 'productnameOne',
                    label: '组套目录',
                    type: 'input'
                },
                组套商品名: {
                    name: 'productnameTwo',
                    label: '组套商品名',
                    type: 'input'
                },
                品种分类: {
                    name: 'sortname',
                    label: '品种分类'
                },
                '生产企业（药监局）': {
                    name: 'companynameScs',
                    label: '生产企业（药监局）'
                },
                采购单位: {
                    name: 'fillout',
                    label: '采购单位'
                },
                产品包装单位: {
                    name: 'goodsunit',
                    label: '产品包装单位'
                },
                产品描述: {
                    name: 'performance',
                    label: '产品描述'
                },
                产品备注: {
                    name: 'remark',
                    label: '产品备注'
                },
                组套编号: {
                    name: 'zutaoid',
                    label: '组套编号'
                },
                生产企业名称: {
                    name: 'companynameSc',
                    label: '生产企业名称'
                },
                包装详细: {
                    name: 'pack',
                    label: '包装详细'
                },
                单位: {
                    name: 'unit',
                    label: '单位'
                },
                包装规格: {
                    name: 'packoutlook',
                    label: '包装规格'
                },
                产品包装规格: {
                    name: 'goodspackoutlook',
                    label: '产品包装规格'
                },
                适应症: {
                    name: 'goodsrange',
                    label: '适应症'
                },
                注册证有效截止日期: {
                    name: 'regdate',
                    label: '注册证有效截至日期'
                },
                产品名称英文: {
                    name: 'goodsnameYjen',
                    label: '产品名称英文'
                },
                /** 三明 */
                当前轮次报价状态: {
                    name: 'currentCompetePriceStatusDesc',
                    label: '当前轮次报价状态'
                },
                报价状态1: {
                    name: 'currentCompetePriceStatusDesc',
                    label: '报价状态'
                },
                分类名称sm: {
                    label: '分类名称',
                    name: 'classifyName',
                    type: 'input'
                },
                目录信息: {
                    label: '目录信息',
                    name: 'catalogRemark',
                    type: 'input'
                },
                计价单位: {
                    label: '计价单位',
                    name: 'minUnit'
                },
                是否可报价: {
                    label: '是否可报价',
                    name: 'competeStatusDesc'
                },
                上一轮竞价价格: {
                    label: '上一轮竞价价格',
                    name: 'lastCompetePrice'
                },
                当前轮次竞价价格: {
                    label: '当前轮次竞价价格',
                    name: 'currentCompetePrice'
                },
                竞价价格: {
                    label: '竞价价格',
                    name: 'currentCompetePrice'
                },
                报价价格: {
                    label: '报价价格',
                    name: 'currentCompetePrice'
                },
                /** 三明 */
                特殊属性: {
                    name: 'catalogFields',
                    label: '特殊属性'
                },
                产品最小包装: {
                    label: '产品最小包装',
                    name: 'minPackStandard'
                },
                是否报名: {
                    label: '是否报名',
                    name: 'enrolledFlagDesc'
                },
                产品类别: {
                    name: 'productCatalog',
                    label: '产品类别'
                },
                是否带有接头: {
                    name: 'connectFlagDesc',
                    label: '是否带有接头'
                },
                '采购量(按最小计量单位计)': {
                    name: 'procureQty',
                    label: '采购量(按最小计量单位计)'
                },
                '预采购量(按最小计量单位计)': {
                    name: 'requireQty',
                    label: '预采购量(按最小计量单位计)'
                },
                组号: {
                    name: 'rowNum',
                    label: '组号'
                },
                同步状态: {
                    name: 'transSyncStatus',
                    label: '同步状态',
                    type: 'select',
                    options: [
                        {
                            label: '全部',
                            value: ''
                        },
                        {
                            label: '已同步',
                            value: 1
                        },
                        {
                            label: '未同步',
                            value: 0
                        }
                    ]
                },
                企业上传河北最低价: {
                    name: 'minPriceHb',
                    label: '企业上传河北最低价'
                },
                企业上传全国最低价: {
                    name: 'minPriceCountry',
                    label: '企业上传全国最低价'
                },
                医疗机构最低价: {
                    name: 'minPriceHosp',
                    label: '医疗机构最低价'
                },
                医疗机构最高价: {
                    name: 'maxPriceHosp',
                    label: '医疗机构最高价'
                },
                拟挂网价: {
                    name: 'planPubPrice',
                    label: '拟挂网价'
                },
                注册证产品名称: {
                    name: 'cardName',
                    type: 'input',
                    label: '注册证产品名称'
                },
                是否填写医保医用耗材分类与代码: {
                    name: 'nationalCodeFlag',
                    label: '是否填写医保医用耗材分类与代码',
                    type: 'select',
                    options: [
                        {
                            label: '全部',
                            value: ''
                        },
                        {
                            label: '是',
                            value: 1
                        },
                        {
                            label: '否',
                            value: 2
                        }
                    ]
                },
                是否填写UDI码: {
                    name: 'singleCodeFlag',
                    label: '是否填写UDI码',
                    type: 'select',
                    options: [
                        {
                            label: '全部',
                            value: ''
                        },
                        {
                            label: '是',
                            value: 1
                        },
                        {
                            label: '否',
                            value: 2
                        }
                    ]
                },
                '医疗器械唯一标识信息编码（UDI）': {
                    name: 'singleCode',
                    label: '医疗器械唯一标识信息编码（UDI）',
                    type: 'input'
                },
                医保医用耗材分类代码: {
                    name: 'nationalCode',
                    label: '医保医用耗材分类代码',
                    type: 'input'
                },

                医保耗材代码: {
                    name: 'code',
                    label: '医保耗材代码',
                    type: 'input'
                },
                医保通用名: {
                    name: 'medicareName',
                    label: '医保通用名',
                    type: 'input',
                    grid: 3
                },
                一级分类: {
                    name: 'levelOne',
                    label: '一级分类',
                    type: 'input',
                    grid: 3
                },
                二级分类: {
                    name: 'levelTwo',
                    label: '二级分类',
                    type: 'input',
                    grid: 3
                },
                三级分类: {
                    name: 'levelThree',
                    label: '三级分类',
                    type: 'input',
                    grid: 3
                },
                耗材材质: {
                    name: 'material',
                    label: '耗材材质'
                },
                耗材生产企业: {
                    name: 'manufacturer',
                    label: '耗材生产企业'
                },
                注册备案人: {
                    name: 'registrant',
                    label: '注册备案人'
                },
                单件产品名称: {
                    name: 'itemName',
                    label: '单件产品名称'
                },
                规格型号数: {
                    name: 'specificNo',
                    label: '规格型号数'
                },
                分发状态: {
                    name: 'distributeStatus',
                    label: '分发状态',
                    type: 'select',
                    options: [
                        {
                            label: '全部',
                            value: ''
                        },
                        {
                            label: '已分发',
                            value: 1
                        },
                        {
                            label: '未分发',
                            value: 0
                        }
                    ]
                },
                是否分组: {
                    name: 'isGroup',
                    label: '是否分组',
                    type: 'select',
                    options: [
                        {
                            label: '否',
                            value: 1
                        },
                        {
                            label: '是',
                            value: 2
                        }
                    ],
                    value: 1
                },
                分组时间: {
                    name: 'groupDate',
                    label: '分组时间',
                    type: 'date'
                },
                '上一年度采购数量（最小包装单位）': {
                    label: '上一年度采购数量（最小包装单位）',
                    name: 'lastYearMinPackProcureQty'
                },
                价格是否必填: {
                    name: 'priceFlag',
                    label: '价格是否必填',
                    type: 'radio',
                    required: true,
                    options: [
                        {
                            label: '是',
                            value: 1
                        },
                        {
                            label: '否',
                            value: 0
                        }
                    ]
                },
                价格: {
                    name: 'price',
                    label: '价格'
                },
                全国最低销售价格: {
                    name: 'nationalMinimumPrice',
                    label: '全国最低销售价格'
                },
                单一规格编码: {
                    name: 'oldCardnNo',
                    label: '单一规格编码',
                    type: 'input'
                }
            };
        },
        tableStr() {
            // 获取搜索数据,提取key值,以及name跟label值,形成新的对象
            const data = this.searchStr();
            const keys = Object.keys(data);
            const currentObj = {};
            keys.forEach(key => {
                const obj = {
                    name: data[key].name,
                    label: data[key].label
                };

                currentObj[key] = obj;

                // 未key值添加宽度
                if ((currentObj[key].label.indexOf('时间')) > -1) {
                    Object.assign(currentObj[key], this.commonSetConfig('w160'));
                } else if (currentObj[key].label.length * 20 < 120) {
                    Object.assign(currentObj[key], this.commonSetConfig('w120'));
                } else {
                    Object.assign(currentObj[key], this.commonSetConfig(`w${currentObj[key].label.length * 20}`));
                }
            });

            currentObj['项目状态'].name = 'projectStatusDesc';
            currentObj['配送点选状态'].type = 'input';
            currentObj['配送点选状态'].name = 'deliveryConfirmationStatusDesc';
            currentObj['签约状态'].name = 'contractStatusDesc';

            currentObj['医疗机构等级'].name = 'gradeDesc';
            currentObj['医疗机构级别'].name = 'hospLevelDesc';
            currentObj['医疗机构类型'].name = 'hospTypeDesc';
            currentObj['是否组套目录'].name = 'packageFlagDesc';
            currentObj['类型'].name = 'regTypeDesc';
            currentObj['服务分类'].name = 'queTypeDesc';
            currentObj['服务事项'].name = 'queItem';
            currentObj['处理状态'].name = 'auditStatusDesc';
            currentObj['审核状态'].name = 'auditStatusDesc';
            currentObj['服务状态'].name = 'itemStatusDesc';
            currentObj['数据范围'].name = 'dataTypeDesc';
            currentObj['申诉确认状态'].name = 'confirmStatusDesc';
            currentObj['挂网状态'].name = 'directPubStatusDesc';
            currentObj['直接挂网报价状态'].name = 'quationStatusDesc';
            currentObj['生效状态'].name = 'transSyncStatusDesc';
            currentObj['招标模式'].name = 'projectTypeDesc';
            currentObj['是否为人大或政协委员'].name = 'committeeMemberFlagDesc';
            currentObj['专家科室'].name = 'departmentDesc';
            currentObj['行政职务'].name = 'administrativeDutyDesc';
            currentObj['学历'].name = 'educationDesc';
            currentObj['专家职称'].name = 'expertTitleDesc';
            currentObj['专家类型'].name = 'expertTypeDesc';
            currentObj['专业方向'].name = 'majorDirectionDesc';
            currentObj['性别'].name = 'sexDesc';
            currentObj['职称等级'].name = 'titleLevelDesc';
            currentObj['使用类别'].name = 'useKindDesc';
            currentObj['单位类别'].name = 'workOrgTypeDesc';
            currentObj['专家范围配置'].name = 'scopeFlagDesc';
            currentObj['抽取方式'].name = 'extractModeDesc';
            currentObj['模板状态'].name = 'stateDesc';
            currentObj['模板类型'].name = 'templateTypeDesc';
            currentObj['适用对象'].name = 'applicableObjectsDesc';
            currentObj['合同签订状态'].name = 'contractStatusDesc';
            currentObj['生产企业签订状态'].name = 'orgSignDesc';
            currentObj['配送企业签订状态'].name = 'deliverySignDesc';
            currentObj['医疗机构签订状态'].name = 'hospSignDesc';
            currentObj['配送区域'].name = 'deliveryArea';
            currentObj['注册证状态'].name = 'regCardStatusDesc';
            currentObj['同步状态'].name = 'transSyncStatusDesc';
            currentObj['分发状态'].name = 'distributeStatusDesc';
            currentObj['单一规格编码'].extend.formatter = row => (row.oldCardnNo !== 'A' ? row.oldCardnNo : '');


            // console.log(currentObj);
            return currentObj;
        },
        goPage(url, params) {
            this.$router.push({
                path: url,
                query: params
            });
        },
        // 常用按钮组
        buttonStr() {
            return {
                返回: {
                    label: '返回',
                    name: '返回',
                    scene: 'normal',
                    callback: () => {
                        window.history.back(-1);
                    }
                }
            };
        },
        getButtonConfig(name) {
            return this.buttonStr()[name];
        },
        /**
         * 获取搜索栏目的data配置文件
         * @param {Array} arr 需要配置的搜索key值
         * @param {String} status 为列表中的所有的key添加一种状态, readonly 只读 grid 统一布局
         * @param {params} status 根据status接收某个值
         */
        getSearchConfigInfo(arr, status, params) {
            const searchDataObj = this.searchStr();
            const data = this.getFilterArr(arr, searchDataObj);
            // 判断当前状态是数字还是字符串
            if (typeof status === 'string') {
                if (status) {
                    this.dataOperation(status, params, data);
                    return data;
                }
            } else if (typeof status === 'object') {
                // console.log(typeof status);
                status.forEach((item, i) => {
                    this.dataOperation(item, params[i], data);
                });
            }
            return data;
        },
        /**
         * 获取搜索栏目的data配置文件
         * @param {Array} arr 需要配置的搜索key值
         * @param {String} status 为列表中的所有的key添加一种状态, readonly 只读 grid 统一布局
         * @param {params} status 根据status接收某个值
         */
        getFormConfigInfo(arr, status, params) {
            const searchDataObj = this.searchStr();
            const data = this.getFilterArr(arr, searchDataObj, 1);
            // 判断当前状态是数字还是字符串
            if (typeof status === 'string') {
                if (status) {
                    this.dataOperation(status, params, data);
                    return data;
                }
            } else if (typeof status === 'object') {
                // console.log(typeof status);
                status.forEach((item, i) => {
                    this.dataOperation(item, params[i], data);
                });
            }
            return data;
        },
        /**
         * 根据用户传递的参数,执行某个操作,配合表格,表单数据生成操作使用
         * @param {Array/String} status 类型
         * @param {Array/String} status 要修改的参数
         * @param {Array} data 要操作的数据
         */
        dataOperation(status, params, data) {
            switch (status) {
            case 'readonly': {
                data.forEach(item => {
                    item.readonly = true;
                });
                break;
            }
            case 'grid': {
                data.forEach(item => {
                    item.grid = params;
                });
                break;
            }
            default: break;
            }
        },
        /**
         * 根据key值,获取需要的tableConfig数据
         * @param {Array} arr 一个保存key值得集合
         */
        getTableConfigInfo(arr) {
            const tableDataObj = this.tableStr();
            return this.getFilterArr(arr, tableDataObj);
        },
        /**
         * 根据key值,获取一个表单/表格项的配置对象
         * @param {String} key 获取当前表格或表单的设置文件
         * 备注,最大宽度500,以10递加
         */
        commonSetConfig(key) {
            const config = {};
            // 循环创建列表宽度
            for (let i = 10; i <= 50; i += 1) {
                config[`w${i * 10}`] = {
                    // width: (i * 10),
                    extend: {
                        'min-width': `${i * 10}`
                    }
                };
            }

            return config[key];
        },
        /**
         * 数组后面加对象，并返回
         * @param {arr，obj}
         */
        addOperation(data, op) {
            data.push(op);
            return data;
        }
    }
};
