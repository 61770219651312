/*
 * @Author: hejk
 * @Email: hejk@hxmec.com
 * @Date: 2020-05-26 17:44:09
 * @Last Modified by: hejk
 * @Last Modified time: 2020-05-27 15:03:49
 * @Description: 配送管理（医院端）
 */
import View from '@/components/view';

const orgManage = () => import(/* webpackChunkName: "yy-org-manage" */ '@/pages/aup/hosp-org/proj-inner/distribution-manage/org-manage');
const agreementSign = () => import(/* webpackChunkName: "gl-agreement-sign" */ '@/pages/aup/hosp-org/proj-inner/distribution-manage/agreement-sign');
const contractSign = () => import(/* webpackChunkName: "gl-contract-sign" */ '@/pages/aup/hosp-org/proj-inner/distribution-manage/contract-sign');
const detail = () => import(/* webpackChunkName: "gl-distribution-detail" */ '@/pages/aup/components/distribution-detail');

const routes = [
    {
        path: 'distribution-manage',
        component: View,
        meta: {
            title: '配送管理'
        },
        children: [
            {
                path: 'org-manage',
                component: orgManage,
                meta: {
                    title: '配送企业管理'
                }
            },
            {
                path: 'agreement-sign',
                component: agreementSign,
                meta: {
                    title: '购销协议签订'
                }
            },
            {
                path: 'agreement-sign/detail',
                component: detail,
                meta: {
                    title: '明细',
                    display: 'none'
                }
            },
            {
                path: 'contract-sign',
                component: contractSign,
                meta: {
                    title: '合同签订'
                }
            },
            {
                path: 'contract-sign/detail',
                component: detail,
                meta: {
                    title: '明细',
                    display: 'none'
                }
            }
        ]
    }
];

export default routes;
