/*
 * @Author: tangliang
 * @Email: tangliang@guahao.com
 * @Date: 2020-01-04 14:11:04
 * @Last Modified by: xujh
 * @Last Modified time: 2020-07-09 15:01:46
 * @Description: Description
 * @Route: 共享结果
 */
import View from '@/components/view';

const routes = [
    {
        path: 'share-results',
        component: View,
        meta: {
            title: '共享结果'
        },
        children: [
            {
                path: 'net-show',
                component: () => import(/* webpackChunkName: "yq-net-show" */ '@/pages/aup/enterprise-org/proj-out/share-results/net-show'),
                meta: {
                    title: '共享结果挂网公示'
                }
            },
            {
                path: 'net-sync-results',
                component: () => import(/* webpackChunkName: "yq-net-sync-results" */ '@/pages/aup/enterprise-org/proj-out/share-results/net-sync-results'),
                meta: {
                    title: '挂网公示查询（生产代理）'
                }
            }
           
        ]
    }
];

export default routes;
