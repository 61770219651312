/*
 * @Author: linyq
 * @Email: guanghsy2000@163.com
 * @Date: 2019-11-12 19:10:26
 * @Last Modified by: hejk
 * @Last Modified time: 2020-11-17 17:32:41
 * @Description: 谈判管理
 */
// 产品资料详情
import fetch from '@/utils/fetch';

const ProductDetail = () => import(/* webpackChunkName: "yq-prod-detail" */ '@/pages/aup/enterprise-org/proj-out/declare-manage/product/detail');

export default [
    {
        path: 'encrypt',
        component: () => import(/* webpackChunkName: "qy-negotiations-management-encrypt" */ '@/pages/aup/enterprise-org/proj-inner/negotiations-management/encrypt'),
        meta: {
            title: '谈判报价',
            display: 'none'
        }
    },
    {
        path: 'encrypt-before',
        component: () => import('@/pages/common/notice'),
        meta: {
            title: '谈判报价'
        },
        beforeEnter(to, from, next) {
            fetch({
                url: '/aup/api/aup/enroll/round/negotiation/page/negotiationQuotation',
                params: {
                    projectId: to.query.itemId || 1,
                    pageNo: 1,
                    pageSize: 10
                }
            }).then(res => {
                const { startTime, endTime, status } = res.data;
                to.query.startTime = startTime;
                to.query.endTime = endTime;
                if (status === 0) {
                    to.query.title = '谈判未开始，请耐心等待！';
                    next();
                } else if (status === 2) {
                    to.query.title = '谈判报价环节已暂停，请耐心等待！';
                    next();
                } else if (status === 3) {
                    to.query.title = '谈判报价环节已结束！接下来请到【<router-link to="/aup/enterprise-org/proj-inner/negotiations-management/negotiations-query">谈判确认</router-link>】继续谈判！';
                    next();
                } else {
                    next({
                        path: '/aup/enterprise-org/proj-inner/negotiations-management/encrypt',
                        query: from.query
                    });
                }
            });
        }
    },
    // {
    //     path: 'negotiations-bid',
    //     component: () => import('@/pages/aup/enterprise-org/proj-inner/negotiations-management/negotiations-bid'),
    //     meta: {
    //         title: '谈判报价(未开始)'
    //     }
    // },
    {
        path: 'negotiations-bid/product-detail',
        component: ProductDetail,
        meta: {
            title: '产品资料详情',
            noTag: true,
            display: 'none'
        }
    },
    {
        path: 'negotiations-query/product-detail',
        component: ProductDetail,
        meta: {
            title: '产品资料详情',
            noTag: true,
            display: 'none'
        }
    },
    {
        path: 'negotiations-search/product-detail',
        component: ProductDetail,
        meta: {
            title: '产品资料详情',
            noTag: true,
            display: 'none'
        }
    },
    {
        path: 'negotiations-query',
        component: () => import(/* webpackChunkName: "qy-negotiations-query" */ '@/pages/aup/enterprise-org/proj-inner/negotiations-management/negotiations-query'),
        meta: {
            title: '谈判确认'
        }
    },
    {
        path: 'negotiations-search',
        component: () => import(/* webpackChunkName: "qy-negotiations-search" */ '@/pages/aup/enterprise-org/proj-inner/negotiations-management/negotiations-search'),
        meta: {
            title: '谈判结果公示'
        }
    }
];
