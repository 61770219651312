/*
 * @Author: hejk
 * @Email: hejk@hxmec.com
 * @Date: 2019-11-12 10:41:10
 * @Last Modified by: hejk
 * @Last Modified time: 2020-11-02 11:38:19
 * @Description: 耗材
 */
import View from '@/components/view';
import ProdInfo from '@/pages/aup/components/result-public/prod-info';
import Enterprise from './enterprise'; // 企业端路由
import Hosp from './hosp'; // 医院端路由
import Manage from './manage'; // 管理端路由

export default [{
    path: '/aup',
    component: View,
    meta: {
        title: '耗材集中采购平台',
        noTag: true
    },
    children: [
        ...Enterprise,
        ...Manage,
        ...Hosp,
        {
            path: 'prod-info',
            component: ProdInfo,
            meta: {
                title: '产品详情',
                display: 'none'
            }
        }
    ]
}];
