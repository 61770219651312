/*
 * @Author: linyq
 * @Email: guanghsy2000@163.com
 * @Date: 2019-11-12 19:10:26
 * @Last Modified by: hejk
 * @Last Modified time: 2020-05-11 14:35:21
 * @Description: 目录管理
 */

export default [
    {
        path: 'purchase-management-view',
        component: () => import(/* webpackChunkName: "qy-purchase-amount-view" */ '@/pages/aup/enterprise-org/proj-inner/amount-management/purchase-amount-view'),
        meta: {
            title: '采购报量查看'
        }
    }
];
