/*
 * @Author: hejk
 * @Email: hejk@hxmec.com
 * @Date: 2020-04-23 18:56:35
 * @Last Modified by: hejk
 * @Last Modified time: 2020-05-11 14:30:40
 * @Description: 直接挂网管理
 */
import View from '@/components/view';

const productQuotation = () => import(/* webpackChunkName: "qy-product-quotation" */ '@/pages/aup/enterprise-org/proj-inner/direct-net-manage/product-quotation'); // 产品报价
const resultPublicity = () => import(/* webpackChunkName: "qy-result-publicity" */ '@/pages/aup/enterprise-org/proj-inner/direct-net-manage/result-publicity'); // 直接挂网结果公示

export default [
    {
        path: 'direct-net-manage',
        component: View,
        meta: {
            title: '直接挂网管理'
        },
        children: [
            {
                path: 'product-quotation',
                component: productQuotation,
                meta: {
                    title: '产品报价'
                }
            },
            {
                path: 'result-publicity',
                component: resultPublicity,
                meta: {
                    title: '直接挂网结果公示'
                }
            }
        ]
    }
];
