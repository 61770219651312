/*
 * @Author: hejk
 * @Email: hejk@hxmec.com
 * @Date: 2019-12-10 18:04:09
 * @Last Modified by: hejk
 * @Last Modified time: 2021-09-14 19:47:34
 * @Description: 报价管理
 */

import View from '@/components/view';
import fetch from '@/utils/fetch';

const ProductQuotation = () => import(/* webpackChunkName: "gl-product-quotation" */ '@/pages/aup/manage-org/proj-inner/quotation-manage/product-quotation');
const DecryptedProduct = () => import(/* webpackChunkName: "gl-decrypted-product" */ '@/pages/aup/manage-org/proj-inner/quotation-manage/decrypted-product');
const QuotedProduct = () => import(/* webpackChunkName: "gl-quoted-product" */ '@/pages/aup/manage-org/proj-inner/quotation-manage/quoted-product');
const QuotingProduct = () => import(/* webpackChunkName: "gl-quoting-product" */ '@/pages/aup/manage-org/proj-inner/quotation-manage/quoting-product');
const Decrypt = () => import(/* webpackChunkName: "gl-prod-quote-decrypt" */ '@/pages/aup/manage-org/proj-inner/quotation-manage/prod-quote-decrypt');
const Chosen = () => import(/* webpackChunkName: "gl-chosen" */ '@/pages/aup/manage-org/proj-inner/quotation-manage/chosen');
const catalogDecrypt = () => import(/* webpackChunkName: "gl-catalog-decrypt" */ '@/pages/aup/manage-org/proj-inner/quotation-manage/catalog-decrypt');

const routes = [
    {
        path: 'quotation-manage',
        component: View,
        meta: {
            title: '报价管理'
        },
        children: [
            {
                path: 'product-quotation',
                component: ProductQuotation,
                meta: {
                    title: '产品报价查询'
                }
            },
            {
                path: 'product-quotation/quoting-product',
                component: QuotingProduct,
                meta: {
                    title: '可报价产品数',
                    display: 'none'
                }
            },
            {
                path: 'product-quotation/quoted-product',
                component: QuotedProduct,
                meta: {
                    title: '已报价产品数',
                    display: 'none'
                }
            },
            {
                path: 'product-quotation/decrypted-product',
                component: DecryptedProduct,
                meta: {
                    title: '已解密产品数',
                    display: 'none'
                }
            },
            {
                path: 'decrypt',
                component: Decrypt,
                meta: {
                    title: '报价解密-已开始',
                    display: 'none'
                }
            },
            {
                path: 'decrypt-before',
                component: () => import('@/pages/common/notice'),
                meta: {
                    title: '报价解密'
                },
                beforeEnter: (to, from, next) => {
                    fetch({
                        url: '/aup/api/aup/enroll/round/quotation/summary',
                        method: 'post',
                        params: {
                            projectId: to.query.itemId || 1
                        }
                    }).then(res => {
                        const { status } = res.data;
                        to.query.startTime = res.data.startTime;
                        to.query.endTime = res.data.endTime;
                        if (status === 0) {
                            to.query.title = '解密环节未启动！';
                            next();
                        } else if (status === 3) {
                            to.query.title = '解密环节已结束！';
                            next();
                        } else if (status === 2) {
                            to.query.title = '解密环节已暂停！';
                            next();
                        } else {
                            next({
                                path: '/aup/manage-org/proj-inner/quotation-manage/decrypt',
                                query: from.query
                            });
                        }
                    });
                }
            },
            {
                path: 'chosen',
                component: Chosen,
                meta: {
                    title: '报价入选管理'
                }
            },
            {
                path: 'catalog-decrypt',
                component: catalogDecrypt,
                meta: {
                    title: '目录报价解密'
                }
            }
        ]
    }
];

export default routes;
