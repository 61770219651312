/*
 * @Author: hejk
 * @Email: hejk@hxmec.com
 * @Date: 2020-02-25 15:18:15
 * @Last Modified by: hejk
 * @Last Modified time: 2020-02-25 16:45:45
 * @Description: 目录分类弹窗
 */
<template>
    <div>
        <el-dialog
            :title="title"
            :visible="dialogVisible"
            width="900px"
            :show-close="false">
            <el-checkbox v-model="checkAll"
                         :indeterminate="isIndeterminate"
                         @change="handleCheckAllChange">
                全选
            </el-checkbox>
            <div style="margin: 15px 0;" />
            <el-checkbox-group v-model="checkedCata"
                               @change="handleCheckedItemChange">
                <el-checkbox v-for="item in catalogueList"
                             :key="item"
                             :label="item">
                    {{ item }}
                </el-checkbox>
            </el-checkbox-group> 
            <span slot="footer"
                  class="dialog-footer">
                <el-button @click="close">取 消</el-button>
                <el-button type="primary"
                           @click="sure">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import { newCatalogue, oldCatalogue } from '@/utils/options/catalogue';

export default {
    props: {
        catalogueType: {
            type: Number,
            default: 0
        },
        catalogueName: {
            type: String,
            default: ''
        },
        results: {
            type: Object,
            default: () => ({})
        }
    },
    data() {
        return {
            dialogVisible: true,
            title: '',
            catalogueList: [],
            isIndeterminate: true,
            checkedCata: [],
            checkAll: false
        };
    },
    watch: {
        catalogueType: {
            handler(val) {
                this.setInit(val);
            },
            deep: true,
            immediate: true
        }
    },
    methods: {
        /**
         * 设置弹窗初始内容
         * @param {Number} 弹窗类型 1：原分类目录  2：新分类目录
         */
        setInit(type) {
            if (type === 1) {
                this.title = '原分类目录';
                this.catalogueList = oldCatalogue;
            } else {
                this.title = '新分类目录';
                this.catalogueList = newCatalogue;
            }
            const cataData = this.results[this.catalogueName];
            if (cataData) {
                this.checkedCata = cataData.split('；');
            } else {
                this.checkedCata = [];
            }
            const checkedCount = this.checkedCata.length;
            this.checkAll = checkedCount === this.catalogueList.length;
            this.isIndeterminate = checkedCount > 0 && checkedCount < this.catalogueList.length;
        },
        handleCheckAllChange(val) {
            this.checkedCata = val ? this.catalogueList : [];
            this.isIndeterminate = false;
        },
        handleCheckedItemChange(value) {
            const checkedCount = value.length;
            this.checkAll = checkedCount === this.catalogueList.length;
            this.isIndeterminate = checkedCount > 0 && checkedCount < this.catalogueList.length;
        },
        close() {
            this.$emit('closeCata');
        },
        sure() {
            const { catalogueName, checkedCata } = this;
            this.$emit('changeCata', catalogueName, checkedCata.join('；'));
        }
    }
};
</script>
