/*
 * @Author: linyq
 * @Email: guanghsy2000@163.com
 * @Date: 2019-11-12 20:45:07
 * @Last Modified by: hejk
 * @Last Modified time: 2020-05-27 15:47:50
 * @Description: 配送方案管理
 */

const contractSign = () => import(/* webpackChunkName: "qy-contract-sign" */ '@/pages/aup/enterprise-org/proj-inner/distribution-project-manage/contract-sign');
const detail = () => import(/* webpackChunkName: "qy-distribution-detail" */ '@/pages/aup/components/distribution-detail');
const ProgrammeQuery = () => import(/* webpackChunkName: "qy-programme-query" */ '@/pages/aup/enterprise-org/proj-inner/distribution-project-manage/programme-query');
const agreementSign = () => import(/* webpackChunkName: "qy-agreement-sign" */ '@/pages/aup/enterprise-org/proj-inner/distribution-project-manage/agreement-sign');

export default [
    {
        path: 'programme-query',
        component: ProgrammeQuery,
        meta: {
            title: '配送方案查询'
        }
    },
    {
        path: 'contract-sign',
        component: contractSign,
        meta: {
            title: '合同签订'
        }
    },
    {
        path: 'agreement-sign',
        component: agreementSign,
        meta: {
            title: '购销协议签订'
        }
    },
    {
        path: 'agreement-sign/detail',
        component: detail,
        meta: {
            title: '明细',
            display: 'none'
        }
    },
    {
        path: 'contract-sign/detail',
        component: detail,
        meta: {
            title: '明细',
            display: 'none'
        }
    }
];
