/*
 * @Author: linyq
 * @Email: guanghsy2000@163.com
 * @Date: 2020-01-13 15:57:32
 * @Last Modified by: xujh
 * @Last Modified time: 2020-07-09 15:02:51
 * @Description: 企业端 申报管理 路由
 */
import View from '@/components/view';

const EnterpriseIndex = () => import(/* webpackChunkName: "yq-enterprise-index" */ '@/pages/aup/enterprise-org/proj-out/declare-manage/enterprise'); // 企业资料申报
const EnterpriseDetail = () => import(/* webpackChunkName: "yq-enterprise-detail" */ '@/pages/aup/enterprise-org/proj-out/declare-manage/enterprise/detail'); // 企业资料详情
const EnterpriseContrast = () => import(/* webpackChunkName: "yq-enterprise-contrast" */ '@/pages/aup/enterprise-org/proj-out/declare-manage/enterprise/contrast'); // 企业比对查看

const ProductIndex = () => import(/* webpackChunkName: "yq-prod-index" */ '@/pages/aup/enterprise-org/proj-out/declare-manage/product'); // 产品资料申报
const ProductDetail = () => import(/* webpackChunkName: "yq-prod-detail" */ '@/pages/aup/enterprise-org/proj-out/declare-manage/product/detail'); // 产品资料详情
const ProductEdit = () => import(/* webpackChunkName: "yq-prod-edit" */ '@/pages/aup/enterprise-org/proj-out/declare-manage/product/edit'); // 耗材编辑
const proChangeLog = () => import(/* webpackChunkName: "yq-prod-chang-log" */ '@/pages/aup/enterprise-org/proj-out/declare-manage/product/change-log'); // 产品变更记录
const proContrast = () => import(/* webpackChunkName: "yq-prod-contrast" */ '@/pages/aup/enterprise-org/proj-out/declare-manage/product/contrast'); // 产品比对查看

export default [
    {
        path: 'declare-manage',
        component: View,
        meta: {
            title: '申报管理'
        },
        children: [
            {
                path: 'enterprise',
                component: EnterpriseIndex,
                meta: {
                    title: '企业资料申报'
                }
            },
            {
                path: 'enterprise/detail',
                component: EnterpriseDetail,
                meta: {
                    title: '企业资料详情',
                    noTag: true,
                    display: 'none'
                }
            },
            {
                path: 'enterprise/contrast',
                component: EnterpriseContrast,
                meta: {
                    title: '企业比对查看',
                    noTag: true,
                    display: 'none'
                }
            },
            {
                path: 'product',
                component: ProductIndex,
                meta: {
                    title: '产品资料申报'
                }
            },
            {
                path: 'product/detail',
                component: ProductDetail,
                meta: {
                    title: '产品资料详情',
                    noTag: true,
                    display: 'none'
                }
            },
            {
                path: 'product/edit',
                component: ProductEdit,
                meta: {
                    title: '耗材编辑',
                    noTag: true,
                    display: 'none'
                }
            },
            {
                path: 'product/change-log',
                component: proChangeLog,
                meta: {
                    title: '变更记录',
                    noTag: true,
                    display: 'none'
                }
            },
            {
                path: 'product/change-log/detail',
                component: ProductDetail,
                meta: {
                    title: '产品资料详情',
                    noTag: true,
                    display: 'none'
                }
            },
            {
                path: 'product/change-log/contrast',
                component: proContrast,
                meta: {
                    title: '产品比对查看',
                    noTag: true,
                    display: 'none'
                }
            }
        ]
    }
];
