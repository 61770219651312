(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("vue"), require("vue-router"), require("element-ui"));
	else if(typeof define === 'function' && define.amd)
		define(["vue", "vue-router", "element-ui"], factory);
	else if(typeof exports === 'object')
		exports["aup"] = factory(require("vue"), require("vue-router"), require("element-ui"));
	else
		root["aup"] = factory(root["vue"], root["vue-router"], root["element-ui"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__18__, __WEBPACK_EXTERNAL_MODULE__119__, __WEBPACK_EXTERNAL_MODULE__123__) {
return 