/*
 * @Author: hejk
 * @Email: hejk@hxmec.com
 * @Date: 2019-11-12 11:19:11
 * @Last Modified by: hejk
 * @Last Modified time: 2020-05-11 14:40:57
 * @Description: 医院端-项目外
 * @Route: Route
 */
import View from '@/components/view';

const ProjectManage = () => import(/* webpackChunkName: "yy-project-manage" */ '@/pages/aup/manage-org/proj-out/project-manage/index'); // 项目管理

// const ProjectList = () => import('@/pages/aup/hosp-org/proj-out/project-manage/project-list');
const routes = [
    {
        path: 'proj-out',
        component: View,
        meta: {
            title: '项目外'
        },
        children: [
            {
                path: 'project-manage',
                component: View,
                meta: {
                    title: '项目管理'
                },
                children: [
                    {
                        path: 'project-list',
                        component: ProjectManage,
                        meta: {
                            title: '项目列表'
                        }
                    }
                ]
            }
        ]
    }
];

export default routes;
