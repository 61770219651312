/*
 * @Author: linsww
 * @Email: linsww@hxmec.com
 * @Date: 2019-12-09 09:37:57
 * @Last Modified by: xujh
 * @Last Modified time: 2020-07-09 15:01:58
 * @Description: 通知公告-通知公告
 */
import View from '@/components/view';

const Index = () => import(/* webpackChunkName: "yq-notice-announcement" */ '@/pages/aup/enterprise-org/proj-out/notice-announcement');
const Details = () => import(/* webpackChunkName: "yq-notice-announcement-details" */ '@/pages/aup/enterprise-org/proj-out/notice-announcement/details');
const routes = [
    {
        path: 'notice-announcement',
        component: View,
        meta: {
            title: '通知公告'
        },
        children: [
            {
                path: 'index',
                component: Index,
                meta: {
                    title: '通知公告'
                }
            },
            {
                path: 'index/details',
                component: Details,
                meta: {
                    title: '通知公告-详情',
                    display: 'none',
                    noTag: true
                }
            }
        ]
    }
];

export default routes;
