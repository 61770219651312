export default {
    baseUrl: '/code', // 运维中心数据服务
    // 上传
    uploadPath: '/code/base/fe/upload', // 上传
    filePath: '/code/base/fe/info', // 文件信息
    downloadPath: '/code/base/fe/download', // 文件信息
    baseOss: {
        '/code': ''
    }
};
