/*
 * @Author: linyq
 * @Email: guanghsy2000@163.com
 * @Date: 2019-11-12 19:10:26
 * @Last Modified by: hejk
 * @Last Modified time: 2021-04-28 14:30:47
 * @Description: 目录管理
 */
const ProductDetail = () => import(/* webpackChunkName: "yq-prod-detail" */ '@/pages/aup/enterprise-org/proj-out/declare-manage/product/detail'); // 产品资料详情
const regResultPublicity = () => import(/* webpackChunkName: "qy-reg-result-publicity" */ '@/pages/aup/enterprise-org/proj-inner/project-registration/reg-result-publicity'); // 报名结果公示

const dataDeclareInfo = () => import(/* webpackChunkName: "qy-data-declare-info" */ '@/pages/aup/enterprise-org/proj-inner/project-registration/data-declare-info'); // 企业资料增补申报
const historyPriceFill = () => import(/* webpackChunkName: "qy-history-price-fill" */ '@/pages/aup/enterprise-org/proj-inner/project-registration/history-price-fill'); 
const historyPriceFillDetail = () => import(/* webpackChunkName: "qy-history-price-fill-detail" */ '@/pages/aup/enterprise-org/proj-inner/project-registration/history-price-fill/detail');
const projKnead = () => import(/* webpackChunkName: "qy-prod-knead" */ '@/pages/aup/enterprise-org/proj-inner/project-registration/prod-knead'); 
const projKneadLog = () => import(/* webpackChunkName: "qy-prod-knead-log" */ '@/pages/aup/enterprise-org/proj-inner/project-registration/prod-knead/log'); 
const projKneaded = () => import(/* webpackChunkName: "qy-prod-knead-kneaded" */ '@/pages/aup/enterprise-org/proj-inner/project-registration/prod-knead/kneaded'); 
const projKneading = () => import(/* webpackChunkName: "qy-prod-knead-kneading" */ '@/pages/aup/enterprise-org/proj-inner/project-registration/prod-knead/kneading'); 
const codeMaintain = () => import(/* webpackChunkName: "qy-code-maintain" */ '@/pages/aup/enterprise-org/proj-inner/project-registration/code-maintain'); 

export default [
    {
        path: 'product-registration',
        component: () => import(/* webpackChunkName: "qy-product-registration" */ '@/pages/aup/enterprise-org/proj-inner/project-registration/product-registration'),
        meta: {
            title: '产品报名'
        }
    },
    {
        path: 'reg-result-publicity',
        component: regResultPublicity,
        meta: {
            title: '报名结果公示'
        }
    },
    {
        path: 'product-registration/product-detail',
        component: ProductDetail,
        meta: {
            title: '产品资料详情',
            noTag: true,
            display: 'none'
        }
    },
    {
        path: 'data-declare-info',
        component: dataDeclareInfo,
        meta: {
            title: '企业资料增补申报'
        }
    },
    {
        path: 'history-price-fill',
        component: historyPriceFill,
        meta: {
            title: '历史价格填报'
        }
    },
    {
        path: 'history-price-fill/detail',
        component: historyPriceFillDetail,
        meta: {
            title: '详情',
            noTag: true,
            display: 'none'
        }
    },
    {
        path: 'history-price-fill/edit',
        component: historyPriceFillDetail,
        meta: {
            title: '编辑',
            noTag: true,
            display: 'none'
        }
    },
    {
        path: 'prod-knead',
        component: projKnead,
        meta: {
            title: '产品捏合'
        }
    },
    {
        path: 'prod-knead/log',
        component: projKneadLog,
        meta: {
            title: '操作日志',
            display: 'none'
        }
    },
    {
        path: 'prod-knead/kneaded',
        component: projKneaded,
        meta: {
            title: '已捏合',
            display: 'none'
        }
    },
    {
        path: 'prod-knead/kneading',
        component: projKneading,
        meta: {
            title: '未捏合',
            display: 'none'
        }
    },
    {
        path: 'code-maintain',
        component: codeMaintain,
        meta: {
            title: '双码维护'
        }
    }
];
